import React, { useState, useEffect, useMemo } from 'react';
import { Typography, Box, Button, Container, Card, CardContent } from '@mui/material';
import ParticlesComponent2 from '../Components/ParticlesComponent2';

const Gracias = () => {
    const particlesComponentMemo = useMemo(() => <ParticlesComponent2 />, []);

    return (
        <div style={{ position: 'relative', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            {particlesComponentMemo}
            <Container maxWidth="sm" style={{ position: 'absolute', zIndex: 2 }}>
                <Card sx={{ opacity: 0.95, backdropFilter: 'blur(10px)' }}>
                    <CardContent sx={{ textAlign: 'center' }}>
                        <Typography variant="h5" component="div" gutterBottom>
                            ¡Gracias por contestar la encuesta!
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                            Tu opinión es muy importante para nosotros.
                        </Typography>
                        <Box mt={2}>
                            <Button variant="contained" color="primary" href="/registrar-idea">
                                Registrar una nueva idea
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Container>
        </div>
    );
};

export default Gracias;
