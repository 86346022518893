import React, { Component } from 'react';
import Terminal from 'react-console-emulator';

const commands = {
  
  txdx: {
    description: '',
    usage: 'txdx',
    fn: () => 'El mejor equipo del mundo'
  },
compucad: {
    description: '',
    usage: 'CCAD',
    fn: () => 'La mejor empresa del mundo'
  }, 
  Eduardo: {
    description: '',
    usage: 'CCAD',
    fn: () => 'lalo es el mejor'
  }, 
  
  Adrian : {
    description: '',
    usage: 'CCAD',
    fn: () => 'adrian es el mejor'
    },
    Luis: {
        description: '',
        usage: 'CCAD',
        fn: () => 'espinoza es el mejor'
    },
    Abraham : {
        description: '',
        usage: 'CCAD',
        fn: () => 'abraham es el mejor'
    },

    Sergio : {
        description: '',
        usage: 'CCAD',
        fn: () => 'sergio es el mejor'
    },


  ayuda: {
        description: 'Lista de comandos disponibles',
        usage: 'help',
        fn: () => '------Ingresa cualquiera de los siguientes comandos-----\n salir\n txdx\n compucad\n Primer nombre de los integrantes del equipo (Adrian,Luis,Abraham,Eduardo,Sergio)'

    },

    salir: {
        description: 'Imprime 100 veces "Estas siendo hackeado"',
        usage: 'hola',
        fn: () => {
          let hola = '';
          for (let i = 0; i < 10000; i++) {
            hola += 'Estas siendo hackeado\n'; 
          }
          return hola;
        }
      }
      
};

class MyTerminal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      attempts: 0,
    };
  }

  handleCommandProcessed = (command, args, result) => {
    if (command === 'txdx' && result !== 'El mejor equipo del mundo') {
      this.setState((prevState) => ({ attempts: prevState.attempts + 1 }));

      if (this.state.attempts >= 5) {
        this.infiniteMalwareInstallation();
      }
    }
  };

  render() {
    const containerStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '600px',
      height: '500px',
      border: '2px solid #000000',
      borderRadius: '8px',
      overflow: 'hidden',
      backgroundColor: '#000000',
      fontFamily: "'Courier New', monospace",
      color: '#ffffff',
      display: 'flex',
      flexDirection: 'column',
     
      overflow: 'hidden',
    };

    const topBarStyle = {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '8px',
      background: '#333',
      borderBottom: '1px solid #555',
    };

    const windowControlStyle = {
      display: 'flex',
      gap: '8px',
      overflow: 'hidden',
    };
    const promptLabel = () => '404@ccad:~$';
    return (
      <div style={containerStyle}>
        <div style={topBarStyle}>
          <span>Terminal 404 </span>
          <div style={windowControlStyle}>
            <span onClick={() => window.close()} style={{ cursor: 'pointer' }}>
              &#10005;
            </span>
            {/* Add other window control icons as needed */}
          </div>
        </div>
        <Terminal
        style={{backgroundColor: '#000000',overflow: 'hidden',}}
          commands={commands}
          promptLabel={'404@ccad:~$'}
          commandCallback={this.handleCommandProcessed}
          noDefaults={true}
        />
      </div>
    );
  }
}

export default MyTerminal;