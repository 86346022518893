const Base = 'https://gestordeproyectos-api.azurewebsites.net';

export async function NotificacionesByUser(correo) {
    try {

        console.log(Base + '/Notificaciones/Usuario/' + correo);
      const response = await fetch(Base + '/Notificaciones/Usuario/' + correo);
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();

      //filtra solo las que tengan el campo visto en false
        const notificaciones = jsonData.filter((notificacion) => notificacion.Visto === "false");
      return notificaciones;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }
  
  export async function PostNotificacion (notificacion) {
    try {
      const response = await fetch(Base + '/Notificaciones', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(notificacion),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function MarcarComoVisto (id) {
    try {
      const response = await fetch(Base + '/Notificaciones/Visto/' + id, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }