import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Box, Card, CardContent, Typography, Container, useMediaQuery,CircularProgress } from '@mui/material';

import { LogoutButton } from "./LogoutButton";
import { LoginButton } from "./LoginButton";
import ParticlesComponent3 from '../Components/ParticlesComponent3';
import { useTheme } from '@mui/material/styles';

const AvisoLogin = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh', // Ajusta según tus necesidades
        p: 3,
      }}
    >
      { isAuthenticated ? (
          <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <div>
        <Box
          sx={{
              position: 'relative',
              height: '100vh',
              width: '100%',
              overflow: 'hidden',
              '&::before': {
                  content: '""',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  backgroundImage: 'url(https://static.wixstatic.com/media/9c7b90_f4b51a4cfbf94766baae2af4cc2a3f02~mv2.png)',
                  backgroundSize: matches ? '20%' : '40%',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                  opacity: '0.1',
              },
          }}
      >
          <ParticlesComponent3 />
   <div style={{ height: '100vh',
              width: '100%' }}> Cargando.................................................................................................</div>
          <Box
              sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 2,
              }}
          >
              <Container component="main" maxWidth="xs">
                  <Card sx={{
                      backgroundColor: 'rgba(255, 255, 255, 0.95)',
                      backdropFilter: 'blur(10px)',
                      boxShadow: theme.shadows[10],
                      padding: theme.spacing(4),
                      borderRadius: theme.shape.borderRadius,
                      border: '1px solid rgba(255, 255, 255, 0.3)',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                  }}>
                          <img src="https://static.wixstatic.com/media/9c7b90_f4b51a4cfbf94766baae2af4cc2a3f02~mv2.png" alt="logo" style={{ width: '100px', height: '50px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

                      <CardContent sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <Typography variant="h4" component="h1" gutterBottom align="center" sx={{ color: theme.palette.primary.main, fontWeight: 'bold', mb: 2 }}>
                              Gestor de proyectos
                          </Typography>
                          <Typography variant="h6" component="h2" gutterBottom align="center" sx={{ mb: 4 }}>
                              Transformación digital
                          </Typography>
                          <Box
sx={{

  width: '100%',
  display: 'flex',       // Habilita flexbox
  justifyContent: 'center' // Centra los elementos a lo largo del eje principal (horizontal)
}}
>
<LoginButton />
</Box>

                      </CardContent>
                  </Card>
              </Container>
          </Box>
      </Box>

        </div>
      )}
    </Box>
  );
};

export default AvisoLogin;
