import React, { useState, useEffect } from 'react';
import { Typography, Box, TextField, Button, Container, Card, CardContent, Rating, FormControlLabel, Checkbox } from '@mui/material';
import { useParams } from 'react-router-dom';
import { GetByIdProyects } from '../APIS/proyectos';
import ParticlesComponent2 from '../Components/ParticlesComponent2';
import { PostEncuesta } from '../APIS/Encuestas';

const ContestarEncuesta = () => {
    const { id, solicitante } = useParams();
    const [proyecto, setProyecto] = useState({});
    const [idProyecto, setIdProyecto] = useState(0);
    const [correo, setCorreo] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const proyectoData = await GetByIdProyects(id);
                setProyecto(proyectoData);
                setIdProyecto(proyecto.id);
            } catch (error) {
                console.error('Error al obtener el proyecto:', error);
            }
        };
        fetchData();
    }, [id]);

    const [respuestas, setRespuestas] = useState({
        IDSolicitante: parseInt(solicitante),
        IDProyecto: parseInt(idProyecto),
        Codigo: id,
        Correo: 'Sin logeo (Encuesta)',
        FechaLimite: "2024-03-27 22:12:05.061 +00:00",
        FechaRespuesta: "2024-03-27 22:12:05.061 +00:00",
        Status: "Contestado",
        P1: 0,
        P2: 0,
        P3: 0,
        P4: 0,
        P5: 0,
        P6: 0,
        P7: 0,
        P8: 0,
        P9: '',
        P10: ''
    });

    useEffect(() => {
        if (proyecto.id) {
            setRespuestas(prev => ({ ...prev, IDProyecto: proyecto.id, P2: 0, P7: 0 })); // Valores predeterminados para P2 y P7
        }
    }, [proyecto]);

    const handleRatingChange = (event, newValue, preguntaId) => {
        setRespuestas(prev => ({ ...prev, [preguntaId]: newValue }));
    };

    const handleCheckboxChange = (option, checked) => {
        let currentSelections = respuestas.P9 ? respuestas.P9.split(', ') : [];
        if (checked) {
            currentSelections.push(option);
        } else {
            currentSelections = currentSelections.filter(item => item !== option);
        }
        setRespuestas({ ...respuestas, P9: currentSelections.join(', ') });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        // Verifica que todas las preguntas hayan sido respondidas
        if (Object.values(respuestas).some(value => value === '')) {
            alert('Por favor, responde todas las preguntas');
            return;
        }
    
        // Preparar las respuestas para el envío, convirtiendo a entero donde sea necesario
        const respuestasPreparadas = {
            ...respuestas,
            P1: parseInt(respuestas.P1),
            P3: parseInt(respuestas.P3),
            P4: parseInt(respuestas.P4),
            P5: parseInt(respuestas.P5),
            P6: parseInt(respuestas.P6),
            P8: parseInt(respuestas.P8),
            // No es necesario convertir P9 y P10 ya que son de tipo diferente
        };
    
        console.log(respuestasPreparadas);
        try {
            await PostEncuesta(respuestasPreparadas);
            alert('Encuesta enviada con éxito');
            window.location.href = '/gracias';
        } catch (error) {
            console.error('Error al enviar la encuesta:', error);
            alert('Error al enviar la encuesta');
        }
    };

    const preguntas = [
        { id: "P1", pregunta: "¿En qué medida los resultados del desarrollo cumplieron con tus expectativas?" },
        { id: "P3", pregunta: "¿Los tiempos de desarrollo se alinearon con lo planificado?" },
        { id: "P4", pregunta: "¿En cuanto a las pruebas y entrega funcional del desarrollo, cómo calificarías la gestión de tiempo?" },
        { id: "P5", pregunta: "¿Cómo evaluarías la facilidad de uso y experiencia de usuario?" },
        { id: "P6", pregunta: "¿Fue el periodo de garantía suficiente para detectar y corregir inconsistencias en el entorno de producción?" },
        { id: "P8", pregunta: "Califica el proceso general de Transformación Digital en una escala del 1 al 5, donde 1 es 'Muy insatisfecho' y 5 es 'Muy satisfecho'" },
        { id: "P9", pregunta: "¿Qué aspectos del servicio de TD consideras que requieren mejora? (Selecciona todas las opciones que apliquen)", opciones: ["Tiempos de aprobación del proyecto", "Comunicación con el equipo de desarrollo", "Participación o involucramiento del PM", "Tiempos de planeación", "Tiempos de desarrollo", "Proceso de pruebas", "Soporte post-entrega"] }
    ];

    return (
        <div style={{ position: 'relative' }}>
            <ParticlesComponent2 />
            <div style={{ position: 'relative', zIndex: 2 }}>
                <Container maxWidth="md">
                    <br />
                    <Card sx={{ mb: 2, boxShadow: 3 }}>
                        <br />
                        <img src="https://static.wixstatic.com/media/9c7b90_f4b51a4cfbf94766baae2af4cc2a3f02~mv2.png" alt="logo" style={{ width: '100px', height: '50px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Typography
                                variant="h4"
                                sx={{
                                    mt: 4,
                                    mb: 2,
                                    textAlign: 'center', // Centra el texto horizontalmente
                                    color: 'primary.main', // Usa el color principal del tema para el texto
                                    fontWeight: 'bold', // Hace que el texto sea negrita
                                    fontSize: '2rem', // Ajusta el tamaño del texto (opcional)
                                    background: 'linear-gradient(to right, #0067A0, #0067A0)', // Gradiente de fondo (opcional)
                                    WebkitBackgroundClip: 'text', // Necesario para el efecto de clip de fondo
                                    WebkitTextFillColor: 'transparent', // Hace el texto transparente para mostrar el fondo
                                    display: 'inline-block', // Hace que el gradiente se aplique solo al texto
                                    mb: 4, // Margen inferior para dar más espacio
                                }}
                            >
                                Encuesta de Satisfacción - {proyecto.NombreProyecto}
                            </Typography>
                        </Box>
                    </Card>
                    <form onSubmit={handleSubmit}>
                        {preguntas.map((pregunta, index) => (
                            <Card key={index} sx={{ mb: 2 }}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>{pregunta.pregunta}</Typography>
                                    {pregunta.id !== "P9" ? (
                                        <Rating
                                            name={pregunta.id}
                                            value={respuestas[pregunta.id]}
                                            onChange={(event, newValue) => handleRatingChange(event, newValue, pregunta.id)}
                                            max={5}
                                        />
                                    ) : (
                                        pregunta.opciones.map((opcion, index) => (
                                            <FormControlLabel
                                                key={index}
                                                control={
                                                    <Checkbox
                                                        checked={respuestas.P9.split(', ').includes(opcion)}
                                                        onChange={(e) => handleCheckboxChange(opcion, e.target.checked)}
                                                    />
                                                }
                                                label={opcion}
                                            />
                                        ))
                                    )}
                                </CardContent>
                            </Card>
                        ))}
                        <Card sx={{ mb: 2 }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>Comentarios adicionales</Typography>
                                <TextField
                                    fullWidth
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    name="P10"
                                    value={respuestas.P10}
                                    onChange={(e) => setRespuestas({ ...respuestas, P10: e.target.value })}
                                />
                            </CardContent>
                        </Card>
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                            <Button type="submit" variant="contained" color="primary" onClick={handleSubmit}>Enviar Encuesta</Button>
                            <br />
                        </Box>
                    </form>
                </Container>
            </div>
        </div>
    );
};

export default ContestarEncuesta;
