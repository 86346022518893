import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Card, CardContent, CardActions, Button, Grid } from '@mui/material';
import MyDrawerComponent from '../Components/NavBar';
import { GetSolicitudByid,UpdateSolicitud,GetJefeByCorreo } from '../APIS/Solicitudes';
import AvisoLogin from '../Components/AvisoLogin';
import { useAuth0 } from "@auth0/auth0-react";
import { SendTeamsAlertProyecto } from '../APIS/proyectos';

const DetallesSolicitudes = () => {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const correo = "prueba";
    useEffect(() => {
        const fetchDataFromApi = async () => {
            try {
                const jsonData = await GetSolicitudByid(id);
                setData(jsonData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchDataFromApi();
    }, [id]);



    const handleAccept = async () => {
        
        const data2 = {
            id: id,
            Status: "Aceptado"
        };

        try {
            await UpdateSolicitud(data2);
            console.log("Aceptado");
            window.location.href = '/';
            // SendTeamsAlertProyecto("","Prueba", "abraham.pardo@compucad.com.mx", "abraham.pardo@compucad.com.mx", "abraham.pardo@compucad.com.mx", "Aprobado");

        } catch (error) {
            console.error('Error al aceptar la solicitud:', error);
            // Manejar el error, por ejemplo, mostrar un mensaje de error al usuario
        }
    };

    const handleReject = async () => {
        const data2 = {
            id: id,
            Status: "Rechazado"
        };

        try {
            await UpdateSolicitud(data2);

           const InfoJefe = await GetJefeByCorreo(data.solicitante.Correo); 
            // console.log("Rechazada");Codigo,NombreProyecto, Desarrollador,                    PM,                           Solicitante,                Sponsor,                     Status, MotivoDesconti
            await SendTeamsAlertProyecto("00000",data.Idea, "abraham.pardo@compucad.com.mx", "abraham.pardo@compucad.com.mx", data.solicitante.Correo,InfoJefe.correo , "Rechazado" , "Motivo de rechazo");



             window.location.href = '/solicitudes';
        } catch (error) {
            console.error('Error al rechazar la solicitud:', error);
        }
    };


    return (
        <div>
            { useAuth0().isAuthenticated ? (
                <div>
            <MyDrawerComponent pageName="Detalles de la solicitud" selectedMenuItem="Contestar Encuesta" />
            <Box sx={{ flexGrow: 1, m: 2, display: 'flex', justifyContent: 'center' }}>
                {data ? (
                    <Card sx={{ maxWidth: 600, m: 1 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" gutterBottom>
                                Detalles de la Solicitud
                            </Typography>
                            <Box sx={{ my: 2 }}>
                                <Typography variant="body1"><b>Idea:</b> {data.Idea}</Typography>
                                <Typography variant="body1"><b>Descripción:</b> {data.Descripción}</Typography>
                                <Typography variant="body1"><b>¿La solicitud es parte de algún proceso?</b> {data.TieneProceso}</Typography>
                                <Typography variant="body1"><b>UN:</b> {data.UN}</Typography>
                                <Typography variant="body1"><b>Clasificación:</b> {data.Clasificacion}</Typography>
                                <Typography variant="body1"><b>Impacto:</b> {data.Impacto}</Typography>
                                <Typography variant="body1"><b>Folio:</b> {data.Folio}</Typography>
                                <Typography variant="body1"><b>Status:</b> {data.Status}</Typography>
                                <Typography variant="body1"><b>Fecha de Registro:</b> {new Date(data.FechaRegistro).toLocaleDateString()}</Typography>
                            </Box>
                            <Typography variant="h6" component="div">
                                Solicitante
                            </Typography>
                            <Typography variant="body1"><b>Nombre:</b> {data.solicitante.Nombre}</Typography>
                            <Typography variant="body1"><b>Correo:</b> {data.solicitante.Correo}</Typography>
                            <Typography variant="body1"><b>Unidad de Negocio:</b> {data.solicitante.UN}</Typography>
                        </CardContent>
                        <CardActions>
                        {data.Status === "Aceptado" || data.Status === "Rechazado" ? (
                                <Typography variant="body1" color="error">No se puede cambiar el status de esta solicitud.</Typography>
                            ) : (
                                <CardActions>
                             <Button variant="contained" color="success" onClick={handleAccept}>Aceptar</Button>
                            <Button variant="contained" color="error" onClick={handleReject}>Rechazar</Button>
                            </CardActions>
                            )}
                        </CardActions>
                    </Card>
                ) : (
                    <Typography>Cargando detalles...</Typography>
                )}
            </Box>
            </div>
            ) : (
                <AvisoLogin />
            )}
        </div>
    );
};

export default DetallesSolicitudes;
