import React, { useEffect, useRef, useState, useMemo } from 'react';
import MyDrawerComponent from '../Components/NavBar';
import Chart from 'chart.js/auto';
import GridComponent from '../Components/GridComponent';
import { GetPremedioPreguntas, GetAllEncuestas2 } from '../APIS/Encuestas';
import { GetAllSolicitudes } from '../APIS/Solicitudes';
import { useAuth0 } from "@auth0/auth0-react";
import AvisoLogin from '../Components/AvisoLogin';
import { GetAllProyectos } from '../APIS/proyectos';
import { Grid, Card, CardContent, Typography, Box } from '@mui/material';

const ResultadosEncuestas = () => {
  const [modoVisualizacion, setModoVisualizacion] = useState('ambos');
  const barChart1Ref = useRef(null);
  const barChart2Ref = useRef(null);
  const barChart1Instance = useRef(null);
  const barChart2Instance = useRef(null);

  const [promedios, setPromedios] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [promediosProyectos, setPromediosProyectos] = useState([]);
  const [ProyectosConCosto, setProyectosConCosto] = useState([]);

  const PreguntaCompleta = [
    'P1: ¿En qué medida los resultados del desarrollo cumplieron con tus expectativas?',
    'P2: ¿Los tiempos de desarrollo se alinearon con lo planificado?',
    'P3: ¿En cuanto a las pruebas y entrega funcional del desarrollo, ¿cómo calificarías la gestión de tiempo?',
    'P4: ¿Cómo evaluarías la facilidad de uso y experiencia de usuario?',
    'P5: ¿Fue el periodo de garantía suficiente para detectar y corregir inconsistencias en el entorno de producción?',
    'P6: Califica el proceso general de Transformación Digital en una escala del 1 al 5, donde 1 es Muy insatisfecho y 5 es Muy satisfecho'
  ];

  const PreguntaCorta = [
    'Pregunta 1',
    'Pregunta 2',
    'Pregunta 3',
    'Pregunta 4',
    'Pregunta 5',
    'Pregunta 6',
  ];

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const jsonData = await GetPremedioPreguntas();
        setPromedios(jsonData);
        setRespuestas([jsonData.P1, jsonData.P3, jsonData.P4, jsonData.P5, jsonData.P6, jsonData.P8]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }

      try {
        const jsonData = await GetAllEncuestas2();
        setProyectos(jsonData.map((item) => item.NombreProyecto));
        setPromediosProyectos(jsonData.map((item) => item.PromedioGeneral));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataFromApi();
  }, []);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const jsonData = await GetAllProyectos();
        const proyectosConCosto = jsonData.filter((item) => item.Costos > 0);
        setProyectosConCosto(proyectosConCosto);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataFromApi();
  }, []);

  const chartData1 = useMemo(() => ({
    labels: PreguntaCorta,
    datasets: [
      {
        label: 'Respuestas a las Preguntas',
        backgroundColor: 'rgba(95,192,192,0.7)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 3,
        data: respuestas,
      },
    ],
  }), [respuestas]);

  const chartData2 = useMemo(() => ({
    labels: proyectos,
    datasets: [
      {
        label: 'Promedios de Proyectos',
        backgroundColor: 'rgba(75,192,192,0.7)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        data: promediosProyectos,
      },
    ],
  }), [proyectos, promediosProyectos]);

  const getChartOptions = (titulo) => {
    return {
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: titulo,
        },
        tooltip: {
          callbacks: {
            title: function (context) {
              if (titulo === 'Preguntas') {
                return PreguntaCompleta[context[0].dataIndex];
              }
              return context[0].label;
            }
          }
        }
      },
      scales: {
        x: {
          type: 'category',
          title: {
            display: true,
            text: titulo === 'Proyectos' ? 'Proyectos' : 'Preguntas',
          },
        },
        y: {
          type: 'linear',
          position: 'left',
          title: {
            display: true,
            text: titulo === 'Proyectos' ? 'Promedio' : 'Respuestas',
          },
        },
      },
    };
  };

  useEffect(() => {
    if (barChart1Instance.current) {
      barChart1Instance.current.destroy();
    }

    if (barChart2Instance.current) {
      barChart2Instance.current.destroy();
    }

    if (barChart1Ref.current) {
      const barChart1Ctx = barChart1Ref.current.getContext('2d');
      barChart1Instance.current = new Chart(barChart1Ctx, {
        type: 'bar',
        data: chartData1,
        options: getChartOptions('Preguntas'),
      });
    }

    if (barChart2Ref.current) {
      const barChart2Ctx = barChart2Ref.current.getContext('2d');
      barChart2Instance.current = new Chart(barChart2Ctx, {
        type: 'bar',
        data: chartData2,
        options: getChartOptions('Proyectos'),
      });
    }

    return () => {
      barChart1Instance.current?.destroy();
      barChart2Instance.current?.destroy();
    };
  }, [chartData1, chartData2, modoVisualizacion]);

  const cambiarModoVisualizacion = (modo) => {
    setModoVisualizacion(modo);
  };

  return (
    <div>
      {useAuth0().isAuthenticated ? (
        <div>
          <MyDrawerComponent pageName="Estadisticas" selectedMenuItem="Resultados encuestas" />
          <div style={{ margin: '20px' }}>
            <button onClick={() => cambiarModoVisualizacion('ambos')}>Ambos</button>
            <button onClick={() => setModoVisualizacion("preguntas")}>Preguntas</button>
            <button onClick={() => cambiarModoVisualizacion('proyectos')}>Proyectos</button>
            <button onClick={() => cambiarModoVisualizacion('Gastos')}>Gastos</button>
          </div>

          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {modoVisualizacion === 'ambos' && (
              <div style={{ width: '90%', height: '100%', marginLeft: '3%', marginRight: '3%', justifyContent: 'space-around' }}>
                <GridComponent text1="El proyecto mejor calificado es: Compusonico" text2="Promedio: 98" text3="Numero de proyectos: 87" text4="Proyectos en cola: 32" />
                <div style={{ display: 'flex', justifyContent: 'space-around', width: '100%', height: '100%' }}>
                  <div style={{ width: '100%', height: '100%' }}>
                    <canvas ref={barChart1Ref}></canvas>
                  </div>
                  <div style={{ width: '100%', height: '100%' }}>
                    <canvas ref={barChart2Ref}></canvas>
                  </div>
                </div>
              </div>
            )}

            {modoVisualizacion === 'preguntas' && (
              <div style={{ width: '80%', height: '100%' }}>
                <canvas ref={barChart1Ref}></canvas>
                <div style={{ paddingLeft: '6%', paddingRight: '3%' }}>
                  {PreguntaCompleta.map((item, index) => (
                    <div key={index}>
                      <p color='gray'>{item}</p>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {modoVisualizacion === 'proyectos' && (
              <div style={{ width: '88%', height: '100%' }}>
                <canvas ref={barChart2Ref}></canvas>
              </div>
            )}

            {modoVisualizacion === 'Gastos' && (
              <div style={{ width: '88%', height: '100%' }}>
                <h1>Gastos</h1>
                <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                  <thead>
                    <tr>
                      <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Nombre del Proyecto</th>
                      <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Costos</th>
                      <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Frecuencia de cobro</th>
                      <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Desglose</th>
                    </tr>
                  </thead>
                  <tbody>
                    {ProyectosConCosto.map((item, index) => (
                      <tr key={index}>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.NombreProyecto}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{"$" + item.Costos}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.FrecuenciaCobro}</td>
                        <td style={{ border: '1px solid black', padding: '8px' }}>{item.DesgloceCostos ? item.DesgloceCostos : "Sin detalles"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Box padding={3}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} md={6}>
                      <Card>
                        <CardContent>
                          <Typography variant="h5" component="h2">
                            Total de costos mensuales
                          </Typography>
                          <Typography variant="h4" component="p">
                            ${ProyectosConCosto.reduce((acc, item) => {
                              if (item.FrecuenciaCobro === 'Mensual') {
                                return acc + item.Costos;
                              } else if (item.FrecuenciaCobro === 'Anual') {
                                return acc + item.Costos / 12;
                              } else {
                                return acc;
                              }
                            }, 0).toFixed(2)}
                          </Typography>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </div>
            )}
          </div>
        </div>
      ) : (
        <AvisoLogin />
      )}
    </div>
  );
};

export default ResultadosEncuestas;
