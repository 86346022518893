import React, { useState, useEffect } from 'react';
import { GetAllMatrizProyectos, CreateMatrizProyectos, deleteElement } from '../APIS/Matriz';
import { GetAllSolicitudes } from '../APIS/Solicitudes';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  IconButton,
  Typography,
  Box,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Autocomplete
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SortIcon from '@mui/icons-material/Sort';

const initialFormState = {
  CodigoProyecto: '',
  ImpactoObjetivosEstrategicos: 0,
  HerramientasInternas: 0,
  EsInnovador: 0,
  Documentado: 0,
  RequiereInversion: 0,
  FrecuenciaUso: 0,
  ResuelvePuntoDolor: 0,
  DesarrolloInterno: 0,
  RevisionTecnica: '',
  ComentariosVarios: '',
  Total: 0
};

const MatrizPrioridad = () => {
  const [proyectos, setProyectos] = useState([]);
  const [filteredProyectos, setFilteredProyectos] = useState([]);
  const [solicitudesCombo, setSolicitudesCombo] = useState([]);
  const [editingRow, setEditingRow] = useState();
  const [sortOrder, setSortOrder] = useState('desc');
  const [filter, setFilter] = useState({ CodigoProyecto: '', Total: 'desc' });
  const [commentModalOpen, setCommentModalOpen] = useState(false);
  const [currentComment, setCurrentComment] = useState('');
  const [currentRevisionTecnica, setCurrentRevisionTecnica] = useState('');
  const [currentRowIndex, setCurrentRowIndex] = useState(null);
  const [editingField, setEditingField] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      const matrizData = await GetAllMatrizProyectos();
      setProyectos(matrizData);
      setFilteredProyectos(matrizData);
      let solicitudesData = await GetAllSolicitudes();
      
      // Filtra las que tengan Status = 'En revisión'
      solicitudesData = solicitudesData.filter((solicitud) => solicitud.Status === 'En revisión');
      setSolicitudesCombo(solicitudesData);
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilter((prev) => ({ ...prev, [name]: value }));
  };

  const applyFilterAndSort = () => {
    let updatedProyectos = [...proyectos];

    // Filtrar
    if (filter.CodigoProyecto) {
      updatedProyectos = updatedProyectos.filter((proyecto) =>
        proyecto.CodigoProyecto.toLowerCase().includes(filter.CodigoProyecto.toLowerCase())
      );
    }

    // Ordenar
    if (filter.Total) {
      updatedProyectos.sort((a, b) =>
        filter.Total === 'asc'
          ? a.Total - b.Total
          : b.Total - a.Total
      );
    }

    setFilteredProyectos(updatedProyectos);
  };

  useEffect(() => {
    applyFilterAndSort();
  }, [filter, proyectos]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let newValue = name === 'CodigoProyecto' || name === 'RevisionTecnica' || name === 'ComentariosVarios' ? value : parseInt(value, 10);

    if (name !== 'CodigoProyecto' && name !== 'RevisionTecnica' && name !== 'ComentariosVarios') {
      if (newValue < 0) newValue = 0;
      if (newValue > 100) newValue = 100;
    }

    setFilteredProyectos((prevProyectos) => {
      const newProyectos = [...prevProyectos];
      newProyectos[index] = {
        ...newProyectos[index],
        [name]: newValue
      };
      return newProyectos;
    });

    const proyectoId = filteredProyectos[index].id;
    setProyectos((prevProyectos) => {
      const newProyectos = [...prevProyectos];
      const originalIndex = prevProyectos.findIndex(proyecto => proyecto.id === proyectoId);
      if (originalIndex !== -1) {
        newProyectos[originalIndex] = {
          ...newProyectos[originalIndex],
          [name]: newValue
        };
      }
      return newProyectos;
    });
  };

  const handleAutocompleteChange = (event, newValue, index) => {
    setFilteredProyectos((prevProyectos) => {
      const newProyectos = [...prevProyectos];
      newProyectos[index].CodigoProyecto = newValue || '';
      return newProyectos;
    });

    const proyectoId = filteredProyectos[index].id;
    setProyectos((prevProyectos) => {
      const newProyectos = [...prevProyectos];
      const originalIndex = prevProyectos.findIndex(proyecto => proyecto.id === proyectoId);
      if (originalIndex !== -1) {
        newProyectos[originalIndex].CodigoProyecto = newValue || '';
      }
      return newProyectos;
    });
  };

  const calculateTotal = (proyecto) => {
    const {
      ImpactoObjetivosEstrategicos,
      HerramientasInternas,
      EsInnovador,
      Documentado,
      RequiereInversion,
      FrecuenciaUso,
      ResuelvePuntoDolor,
      DesarrolloInterno
    } = proyecto;

    return (
      (ImpactoObjetivosEstrategicos * 0.15) +
      (HerramientasInternas * 0.10) +
      (EsInnovador * 0.20) +
      (Documentado * 0.05) +
      (RequiereInversion * 0.10) +
      (FrecuenciaUso * 0.24) +
      (ResuelvePuntoDolor * 0.15) +
      (DesarrolloInterno * 0.01)
    );
  };

  const handleSave = async (index) => {
    const updatedProyecto = {
      ...filteredProyectos[index],
      Total: calculateTotal(filteredProyectos[index])
    };
    try {
      await CreateMatrizProyectos(updatedProyecto);
      setFilteredProyectos((prevProyectos) => {
        const newProyectos = [...prevProyectos];
        newProyectos[index] = updatedProyecto;
        return newProyectos;
      });
      // También actualizamos el estado original 'proyectos'
      setProyectos((prevProyectos) => {
        const newProyectos = [...prevProyectos];
        const originalIndex = prevProyectos.findIndex(proyecto => proyecto.id === updatedProyecto.id);
        if (originalIndex !== -1) {
          newProyectos[originalIndex] = updatedProyecto;
        }
        return newProyectos;
      });
      setEditingRow(null);
   
      window.location.reload();
    } catch (error) {
      console.error('Error al guardar el proyecto:', error);
    }
  };

  const handleDelete = async (id, index) => {
    try {
      await deleteElement(id);
      setFilteredProyectos((prevProyectos) => prevProyectos.filter((_, i) => i !== index));
      setProyectos((prevProyectos) => prevProyectos.filter((proyecto) => proyecto.id !== id));
      window.location.reload();

    } catch (error) {
      console.error('Error al eliminar el proyecto:', error);
    }
  };

  const handleAddRow = () => {
    setProyectos((prevProyectos) => [...prevProyectos, { ...initialFormState }]);
    setFilteredProyectos((prevProyectos) => [...prevProyectos, { ...initialFormState }]);
    setEditingRow(proyectos.length);
  };

  const handleSortChange = () => {
    const newOrder = sortOrder === 'asc' ? 'desc' : 'asc';
    setSortOrder(newOrder);
    setFilter((prevFilter) => ({ ...prevFilter, Total: newOrder }));
  };

  const handleOpenCommentModal = (index, field) => {
    setEditingField(field);
    setCurrentRowIndex(index);
    if (field === 'ComentariosVarios') {
      setCurrentComment(filteredProyectos[index][field] || '');
    } else {
      setCurrentRevisionTecnica(filteredProyectos[index][field] || '');
    }
    setCommentModalOpen(true);
  };

  const handleCloseCommentModal = () => {
    setCommentModalOpen(false);
    setCurrentRowIndex(null);
    setEditingField('');
  };

  const handleSaveComment = async () => {
    const updatedProyecto = { ...filteredProyectos[currentRowIndex] };
    if (editingField === 'ComentariosVarios') {
      updatedProyecto.ComentariosVarios = currentComment;
    } else {
      updatedProyecto.RevisionTecnica = currentRevisionTecnica;
    }

    try {
      await CreateMatrizProyectos(updatedProyecto); // Aquí se guarda en el backend

      setFilteredProyectos((prevProyectos) => {
        const newProyectos = [...prevProyectos];
        newProyectos[currentRowIndex] = updatedProyecto;
        return newProyectos;
      });

      const proyectoId = filteredProyectos[currentRowIndex].id;
      setProyectos((prevProyectos) => {
        const newProyectos = [...prevProyectos];
        const originalIndex = prevProyectos.findIndex(proyecto => proyecto.id === proyectoId);
        if (originalIndex !== -1) {
          newProyectos[originalIndex] = updatedProyecto;
        }
        return newProyectos;
      });

      setCommentModalOpen(false);
      setCurrentRowIndex(null);
      setEditingField('');
    } catch (error) {
      console.error('Error al guardar el comentario:', error);
    }
  };

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" gutterBottom>
        Matriz de Prioridad de Proyectos
      </Typography>
      <Box sx={{ display: 'flex', gap: 1, marginBottom: 1 }}>
        <TextField
          label="Filtrar por idea"
          name="CodigoProyecto"
          value={filter.CodigoProyecto}
          onChange={handleFilterChange}
          variant="outlined"
          size="small"
        />
        <FormControl sx={{ minWidth: 150 }}>
          <Select
            name="Total"
            value={filter.Total}
            onChange={handleFilterChange}
            variant="outlined"
            size="small"
          >
            <MenuItem value="asc" style={{width:'100%'}}>Ascendente</MenuItem>
            <MenuItem value="desc" style={{width:'100%'}}>Descendente</MenuItem>
          </Select>
        </FormControl>
        <Tooltip title="Aplicar Orden">
          <IconButton size="small" onClick={handleSortChange}>
            <SortIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Agregar Nuevo Proyecto">
          <IconButton
            color="primary"
            onClick={handleAddRow}
            size="small"
          >
            <AddCircleOutlineIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>
      <TableContainer component={Paper} sx={{
        '&::-webkit-scrollbar': {
          width: '6px', // Ajusta el ancho de la barra de desplazamiento
          height: '6px' // Ajusta la altura de la barra de desplazamiento horizontal
        },
        '&::-webkit-scrollbar-track': {
          background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#888',
          borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#555',
        },
      }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              {[
                { label: 'Idea', width: 200 },
                'Total',
                'Acciones',
                '(15%) Impacto a objetivos Estratégicos',
                '(10%) ¿Se puede realizar con herramientas internas?',
                '(20%) ¿Es Innovador?',
                '(5%)  ¿Proyecto documentado?',
                '(10%) ¿Requiere inversión adicional?',
                '(24%) Frecuencia de uso esperada',
                '(15%) ¿Resuelve punto dolor?',
                '(1%)  Desarrollado con citizen Developers ',
                'Revisión Técnica',
                'Comentarios Varios',
              ].map((column, index) => (
                <TableCell key={index} sx={{ padding: '4px' }}>
                  <Tooltip title={typeof column === 'string' ? column : column.label} arrow>
                    <span style={{ display: 'block', maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {typeof column === 'string' ? column : column.label}
                    </span>
                  </Tooltip>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredProyectos.map((proyecto, index) => (
              <TableRow key={index}>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <Autocomplete
                      value={proyecto.CodigoProyecto || null}
                      onChange={(event, newValue) => handleAutocompleteChange(event, newValue, index)}
                      options={solicitudesCombo.map((option) => option.Idea)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Idea"
                          variant="outlined"
                          size="small"
                          fullWidth
                        />
                      )}
                    />
                  ) : (
                    <Tooltip title={proyecto.CodigoProyecto} arrow>
                      <span style={{ display: 'block', maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {proyecto.CodigoProyecto}
                      </span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>{calculateTotal(proyecto).toFixed(2)}</TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <Tooltip title="Guardar">
                      <IconButton size="small" onClick={() => handleSave(index)}>
                        <SaveIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title="Editar">
                      <IconButton size="small" onClick={() => setEditingRow(index)}>
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title="Eliminar">
                    <IconButton size="small" onClick={() => handleDelete(proyecto.id, index)}>
                      <DeleteIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </TableCell>

                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="ImpactoObjetivosEstrategicos"
                      type="number"
                      value={proyecto.ImpactoObjetivosEstrategicos}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.ImpactoObjetivosEstrategicos.toString()} arrow>
                      <span>{proyecto.ImpactoObjetivosEstrategicos}</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="HerramientasInternas"
                      type="number"
                      value={proyecto.HerramientasInternas}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.HerramientasInternas.toString()} arrow>
                      <span>{proyecto.HerramientasInternas}</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="EsInnovador"
                      type="number"
                      value={proyecto.EsInnovador}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.EsInnovador.toString()} arrow>
                      <span>{proyecto.EsInnovador}</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="Documentado"
                      type="number"
                      value={proyecto.Documentado}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.Documentado.toString()} arrow>
                      <span>{proyecto.Documentado}</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="RequiereInversion"
                      type="number"
                      value={proyecto.RequiereInversion}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.RequiereInversion.toString()} arrow>
                      <span>{proyecto.RequiereInversion}</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="FrecuenciaUso"
                      type="number"
                      value={proyecto.FrecuenciaUso}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.FrecuenciaUso.toString()} arrow>
                      <span>{proyecto.FrecuenciaUso}</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="ResuelvePuntoDolor"
                      type="number"
                      value={proyecto.ResuelvePuntoDolor}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.ResuelvePuntoDolor.toString()} arrow>
                      <span>{proyecto.ResuelvePuntoDolor}</span>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  {editingRow === index ? (
                    <TextField
                      name="DesarrolloInterno"
                      type="number"
                      value={proyecto.DesarrolloInterno}
                      onChange={(e) => handleChange(e, index)}
                      fullWidth
                      inputProps={{ min: 0, max: 100 }}
                      size="small"
                    />
                  ) : (
                    <Tooltip title={proyecto.DesarrolloInterno.toString()} arrow>
                      <span>{proyecto.DesarrolloInterno}</span>
                    </Tooltip>
                  )}
                </TableCell>
                
                <TableCell sx={{ padding: '4px' }}>
                  <Tooltip title={proyecto.RevisionTecnica || 'Sin comentarios'} arrow>
                    <span
                      style={{ display: 'block', maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer' }}
                      onClick={() => handleOpenCommentModal(index, 'RevisionTecnica')}
                    >
                      {proyecto.RevisionTecnica || 'Sin comentarios'}
                    </span>
                  </Tooltip>
                </TableCell>
                <TableCell sx={{ padding: '4px' }}>
                  <Tooltip title={proyecto.ComentariosVarios || 'Sin comentarios'} arrow>
                    <span
                      style={{ display: 'block', maxWidth: '20ch', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', cursor: 'pointer' }}
                      onClick={() => handleOpenCommentModal(index, 'ComentariosVarios')}
                    >
                      {proyecto.ComentariosVarios || 'Sin comentarios'}
                    </span>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={commentModalOpen} onClose={handleCloseCommentModal}>
        <DialogTitle>Editar {editingField === 'ComentariosVarios' ? 'Comentarios' : 'Revisión Técnica'}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label={editingField === 'ComentariosVarios' ? 'Comentarios' : 'Revisión Técnica'}
            type="text"
            fullWidth
            multiline
            rows={4}
            value={editingField === 'ComentariosVarios' ? currentComment : currentRevisionTecnica}
            onChange={(e) => editingField === 'ComentariosVarios' ? setCurrentComment(e.target.value) : setCurrentRevisionTecnica(e.target.value)}
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveComment} size="small">Guardar</Button>
          <Button onClick={() => setCommentModalOpen(false)} size="small">Cancelar</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default MatrizPrioridad;
