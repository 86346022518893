import React, { useState,useEffect } from 'react';
import { Button, TextField, IconButton, Grid, Typography,Checkbox,FormControlLabel  } from '@material-ui/core';
import { Formik, Form, Field, FieldArray } from 'formik';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import * as Yup from 'yup';
import { Padding } from '@mui/icons-material';
import { makeStyles } from '@material-ui/core/styles';
import {  useParams } from 'react-router-dom';
import { PostSow,GetSowById,PostGenerarDocumento } from '../APIS/Sow';
// import SaveIcon from '@material-ui/icons/Save';  // Icono para guardar
import PrintIcon from '@material-ui/icons/Print';
const useStyles = makeStyles((theme) => ({
  formContainer: {
    paddingLeft: '60px', // Añade padding a la izquierda
    paddingRight: '60px', // Añade padding a la derecha
    boxShadow: '0px 2px 10px rgba(0,0,0,0.40)', // Sombra suave
    borderRadius: '4px', // Bordes redondeados levemente
    marginBottom: '5px', // Margen inferior
    marginTop: '5px', // Margen superior
    backgroundColor: '#fff', // Fondo blanco
    overflowX: 'hidden', // Evita el desbordamiento horizontal
  },
  root: {
    flexGrow: 1, // Asegura que el contenedor tenga un tamaño flexible
    overflow: 'hidden', // Oculta cualquier desbordamiento
    padding: theme.spacing(0, 3), // Añade un padding uniforme
  },
    gridItem: {
      padding: theme.spacing(0), // Añade padding en cada elemento Grid
    },
  }));

 


  
const SowForm = () => {

    const classes = useStyles();
const { id } = useParams();

const formatDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = (`0${date.getMonth() + 1}`).slice(-2); // getMonth() es base 0; añade 1 para hacerlo base 1 y formatea con dos dígitos
  const day = (`0${date.getDate()}`).slice(-2); // Formatea el día con dos dígitos
  return `${year}-${month}-${day}`;
};
function formatDate2(dateStr) {
  const date = new Date(dateStr);
  if (!isNaN(date)) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
  return '';
}
const today = new Date();
const formattedToday = today.toISOString().split('T')[0];
const [initialValues, setInitialValues] = useState({
  idproyecto: id,
  Nombre_Proyecto: '',
  Version: '',
  Autor: '',
  Fecha: formattedToday,
  RazonDeCambio: '',
  Necesidad: '',
  Objetivo: '',
  Alcance: '',
  Beneficios: '',
  DeteccionDeUsuarios: '',
  Diagrama_BD_URL: '',
  Diagrama_Arquitectura_URL: '',
  Notas: '',
  Aprobadores: [
    { idproyecto: id,  Rol: '',Nombre:''}
  ],
  Cronograma: [
    { idproyecto: id,  Actividad: '',Duracion:'',FechaInicio:formattedToday,FechaFin:formattedToday,Responsable:'' }
  ],
  Cruds: [
    { idproyecto: id,  Operacion: '', Funcionalidad: '',Regla:'',acceso:'' }
  ],
  Entregables: [
    { idproyecto: id, Entregable: '', Sponsor: false, Desarrollador: false, Directos_TD: false, Usuarios_Final: false, Solicitante: false }
  ],
  Requerimientos: [
    { idproyecto: id, Requerimiento: '', Responsable: '', Estatus: '' }
  ],
  Riesgos: [
    { idproyecto: id,  Riesgo: '', Verde: '', Amarillo: '', Rojo: '' }
  ],
  Validaciones: [
    { idproyecto: id, Funciones: '', Procedimiento: '' }
  ],
});

const validationSchema = Yup.object({
  Nombre_Proyecto: Yup.string().required('Required'),
  // Add other validations here
});


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await GetSowById(id);
      if (response) {
        const formattedResponse = {
          ...response,
          Fecha: formatDate2(response.Fecha),
          Cronograma: response.Cronograma.map(cronograma => ({
            ...cronograma,
            FechaInicio: formatDate2(cronograma.FechaInicio),
            FechaFin: formatDate2(cronograma.FechaFin)
          })),
          // Continúa aquí para otros campos de fecha que necesiten ser formateados
        };
        setInitialValues(formattedResponse);
      }
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  fetchData();
}, [id]);


const cleanDataBeforeSend = (data) => {
  const newData = { ...data };
  delete newData.id; // Eliminar el id del objeto principal si existe

  // Listas de propiedades a limpiar para cada tipo de datos
  const relations = [
    { key: 'Aprobadores', props: ['id', 'createdAt', 'updatedAt'] },
    { key: 'Cronograma', props: ['id', 'createdAt', 'updatedAt'] },
    { key: 'Cruds', props: ['id', 'createdAt', 'updatedAt'] },
    { key: 'Entregables', props: ['id', 'createdAt', 'updatedAt'] },
    { key: 'Requerimientos', props: ['id', 'createdAt', 'updatedAt'] },
    { key: 'Riesgos', props: ['id', 'createdAt', 'updatedAt'] },
    { key: 'Validaciones', props: ['id', 'createdAt', 'updatedAt'] }
  ];

  // Recorrer cada tipo de datos relacionados para limpiarlos
  relations.forEach(relation => {
    if (newData[relation.key]) {
      newData[relation.key] = newData[relation.key].map(item => {
        const newItem = { ...item };
        relation.props.forEach(prop => delete newItem[prop]); // Eliminar propiedades no deseadas
        return newItem;
      });
    }
  });

  return newData;
};

const handleSubmit = async (values) => {
  console.log('Valores del formulario:', values);

  try {
    const cleanedData = cleanDataBeforeSend(values);
    console.log('Datos limpios para enviar:', cleanedData);

//valida que todos los campos esten llenos y que existan un objeto por lo menos en cada array
    if (cleanedData.Aprobadores.length === 0 || cleanedData.Cronograma.length === 0 || cleanedData.Cruds.length === 0 || cleanedData.Entregables.length === 0 || cleanedData.Requerimientos.length === 0 || cleanedData.Riesgos.length === 0 || cleanedData.Validaciones.length === 0) {
      alert('Por favor, llena todos los campos y agrega al menos un elemento en cada lista');
    }
    const response = await PostSow(cleanedData).then((response) => {
      return response;
    });
    
    console.log('Respuesta del servidor:', response);

    if (response === 'Sow actualizado con éxito') {
      alert('Sow actualizado con éxito');
      window.location.reload();
    } else if (response === 'Sow registrado con éxito') {
      alert('Sow creado con éxito');
      window.location.reload();
    }
  } catch (error) {
    console.error('Error al enviar el formulario:', error);
  }
};


const handleGenerateDocument = async () => {
  try {
   PostGenerarDocumento(id);

  }
  catch (error) {
    console.error('Error al generar el documento:', error);
  }
}

    return (
      <Grid container className={classes.root} justify="center">
      <Grid item xs={12} md={10} lg={10}>
      <Formik
      enableReinitialize // Muy importante para reinicializar el formulario con nuevos valores iniciales
      initialValues={initialValues}
      validationSchema={Yup.object({
        Nombre_Proyecto: Yup.string().required('Required'),
      })}
      onSubmit={handleSubmit}
    >
          {({ values,  setFieldValue, handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Grid container spacing={2} className={classes.formContainer}>
               
                <Grid item xs={12}>
                  <Typography variant="h6">Detalles del Proyecto SOW</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="Nombre_Proyecto" label="Nombre del Proyecto" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Field as={TextField} name="Version" label="Versión" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Autor" label="Autor" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Fecha" label="Fecha sow" type="date" fullWidth   InputLabelProps={{
    shrink: true,  
  }} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="RazonDeCambio" label="Razón de Cambio" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Necesidad" label="Necesidad" fullWidth multiline  rows={4} />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Objetivo" label="Objetivo" fullWidth  multiline  rows={4}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Alcance" label="Alcance" fullWidth multiline  rows={4}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Beneficios" label="Tecnologias utilizadas" fullWidth multiline  rows={2} />
                </Grid>
                <Grid item xs={12}sm={6}>
                    <Field as={TextField} name="DeteccionDeUsuarios" label="Detección de Usuarios" fullWidth  multiline  rows={2}/>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Diagrama_BD_URL" label="URL del Diagrama de BD" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Diagrama_Arquitectura_URL" label="URL del Diagrama de Arquitectura" fullWidth />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Field as={TextField} name="Notas" label="Notas" fullWidth multiline  rows={4} />
                </Grid>
            
    
    
              </Grid>

              <Grid container spacing={2} className={classes.formContainer} >
              <Grid item xs={12} >
                  <Typography variant="h6">Aprobadores</Typography>
                </Grid>

            
                <FieldArray name="Aprobadores">
    {({ push, remove }) => (
      <>
        {values.Aprobadores.map((aprobador, index) => (
          <Grid
            key={index}
            item
            container
            spacing={3}
            alignItems="center"
            style={{
              boxShadow: '0px 2px 10px rgba(0,0,0,0.40)', // Sombra suave
              borderRadius: '4px', // Bordes redondeados levemente
              padding: '16px', // Padding interno
              marginRight: '3px', // Margen derecho
              marginLeft: '20px', // Margen izquierdo
              marginBottom: '20px', // Margen inferior
              marginTop: '20px', // Margen superior
              backgroundColor: '#fff', // Fondo blanco
            }}
          >
            <Grid item xs={5}>
              <Field as={TextField} name={`Aprobadores[${index}].Rol`} label="Rol del Aprobador" fullWidth />
            </Grid>
            <Grid item xs={5}>
              <Field as={TextField} name={`Aprobadores[${index}].Nombre`} label="Nombre del Aprobador" fullWidth />
            </Grid>
            <Grid item>
              {values.Aprobadores.length > 1 && (
                <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
              )}
              <IconButton onClick={() => push({ idproyecto: id, Rol: '', Nombre: '' })}><AddIcon /></IconButton>
            </Grid>
          </Grid>
        ))}
      </>
    )}
  </FieldArray>
              
              </Grid>

              <Grid container spacing={2} className={classes.formContainer}>

                <Grid item xs={12}>
                    <Typography variant="h6">Cronograma</Typography>
                    </Grid>
                    <FieldArray name="Cronograma">
                      {({ push, remove }) => (
                        <>
                          {values.Cronograma.map((cronograma, index) => (
                            <Grid key={index} item container spacing={2} alignItems="center" style={{
                              boxShadow: '0px 2px 10px rgba(0,0,0,0.40)', // Sombra suave
                              borderRadius: '4px', // Bordes redondeados levemente
                              padding: '16px', // Padding interno
                              marginRight: '20px', // Margen derecho
                              marginLeft: '20px', // Margen izquierdo
                              marginBottom: '5px', // Margen inferior
                              marginTop: '5px', // Margen superior
                              backgroundColor: '#fff' // Fondo blanco
                            }}>
                              <Grid item xs={5}>
                                <Field as={TextField} name={`Cronograma[${index}].Actividad`} label="Actividad" fullWidth multiline  rows={4}/>
                              </Grid>
                                <Grid item xs={5}>
                                    <Field as={TextField} name={`Cronograma[${index}].Duracion`} label="Duración" fullWidth />
                                </Grid>
                                <Grid item xs={5}>
                                <Field
  as={TextField}
  name={`Cronograma[${index}].FechaInicio`}
  label="Inicio"
  type="date"
  fullWidth
  InputLabelProps={{
    shrink: true,  
  }}
/>
                                </Grid>
                                <Grid item xs={5}>
                                    <Field as={TextField} name={`Cronograma[${index}].FechaFin`} label="Fin" type="date" fullWidth    InputLabelProps={{
    shrink: true,  
  }}/>
                                </Grid>
                                <Grid item xs={5}>
                                    <Field as={TextField} name={`Cronograma[${index}].Responsable`} label="Responsable" fullWidth />
                                </Grid>

                              <Grid item>
                                { values.Cronograma.length > 1 && (
                                <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
                                )}
                                {index === values.Cronograma.length - 1 && (
                                
                                <IconButton onClick={() => push({ idproyecto: id,  Actividad: '',Duracion:'',FechaInicio:'',FechaFin:'',Responsable:'' })}><AddIcon /></IconButton>
                                )}
                                </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    </FieldArray>
                    </Grid>

                    <Grid container spacing={2} className={classes.formContainer}>
                    <Grid item xs={12}>
                    <Typography variant="h6">Cruds</Typography>
                    </Grid>
                    <FieldArray name="Cruds">
                        {({ push, remove }) => (
                            <>
                            {values.Cruds.map((crud, index) => (
                                <Grid key={index} item container spacing={2} alignItems="center"  style={{
                                  boxShadow: '0px 2px 10px rgba(0,0,0,0.40)', // Sombra suave
                                  borderRadius: '4px', // Bordes redondeados levemente
                                  padding: '16px', // Padding interno
                                  marginRight: '3px', // Margen derecho
                                  marginLeft: '20px', // Margen izquierdo
                                  marginBottom: '20px', // Margen inferior
                                  marginTop: '20px', // Margen superior
                                  backgroundColor: '#fff' // Fondo blanco
                                }}>
                                <Grid item xs={5}>
                                    <Field as={TextField} name={`Cruds[${index}].Operacion`} label="Operación" fullWidth />
                                </Grid>
                                    <Grid item xs={5}>
                                        <Field as={TextField} name={`Cruds[${index}].Funcionalidad`} label="Funcionalidad" fullWidth multiline  rows={2} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Field as={TextField} name={`Cruds[${index}].Regla`} label="Regla" fullWidth multiline  rows={2} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <Field as={TextField} name={`Cruds[${index}].acceso`} label="Acceso" fullWidth />
                                    </Grid>
                                <Grid item>
                                  { values.Cruds.length > 1 && ( 
                                    <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
                                  )}
                                    {index === values.Cruds.length - 1 && (
                                    <IconButton onClick={() => push({ idproyecto: id,  Operacion: '', Funcionalidad: '',Regla:'',acceso:'' })}><AddIcon /></IconButton>
                                    )}
                                    </Grid>
                                </Grid>
                            ))}
                            </>
                        )}
                    </FieldArray>



                    </Grid>



<Grid container spacing={2} className={classes.formContainer}>
<Grid item xs={12}>
<Typography variant="h6">Entregables</Typography>
</Grid>
<FieldArray name="Entregables">
  {({ push, remove, form }) => (
    <>
      {values.Entregables.map((entregable, index) => (
        <Grid key={index} item container spacing={12} alignItems="center" style={{
          boxShadow: '0px 2px 10px rgba(0,0,0,0.40)',
          borderRadius: '4px',
          padding: '16px',
          marginRight: '3px',
          marginLeft: '20px',
          marginBottom: '20px',
          marginTop: '20px',
          backgroundColor: '#fff'
        }}>
          <Grid item xs={2}>
            <Field as={TextField} name={`Entregables[${index}].Entregable`} label="Entregable" fullWidth multiline rows={3}/>
          </Grid>
          {/* Checkbox para Sponsor */}
          <Grid item xs={2}>
            <Field name={`Entregables[${index}].Sponsor`}>
              {({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={() => form.setFieldValue(`Entregables[${index}].Sponsor`, !field.value)}
                    />
                  }
                  label="Sponsor"
                />
              )}
            </Field>
          </Grid>
          {/* Repetir para otros checkboxes */}
          <Grid item xs={2}>
            <Field name={`Entregables[${index}].Desarrollador`}>
              {({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={() => form.setFieldValue(`Entregables[${index}].Desarrollador`, !field.value)}
                    />
                  }
                  label="Desarrollador"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Field name={`Entregables[${index}].Directos_TD`}>
              {({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={() => form.setFieldValue(`Entregables[${index}].Directos_TD`, !field.value)}
                    />
                  }
                  label="Directos TD"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Field name={`Entregables[${index}].Usuarios_Final`}>
              {({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={() => form.setFieldValue(`Entregables[${index}].Usuarios_Final`, !field.value)}
                    />
                  }
                  label="Usuarios Final"
                />
              )}
            </Field>
          </Grid>
          <Grid item xs={2}>
            <Field name={`Entregables[${index}].Solicitante`}>
              {({ field }) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={field.value}
                      onChange={() => form.setFieldValue(`Entregables[${index}].Solicitante`, !field.value)}
                    />
                  }
                  label="Solicitante"
                />
              )}
            </Field>
          </Grid>
          <Grid item>
            {values.Entregables.length > 1 && (
            <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
            )}
            {index === values.Entregables.length - 1 && (
              <IconButton onClick={() => push({ idproyecto: id, Entregable: '', Sponsor: false, Desarrollador: false, Directos_TD: false, Usuarios_Final: false, Solicitante: false })}><AddIcon /></IconButton>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  )}
</FieldArray>

</Grid>


<Grid container spacing={2} className={classes.formContainer}>
<Grid item xs={12}>
<Typography variant="h6">Requerimientos</Typography>
</Grid>
<FieldArray name="Requerimientos">
    {({ push, remove }) => (
        <>
        {values.Requerimientos.map((requerimiento, index) => (
            <Grid key={index} item container spacing={2} alignItems="center"  style={{
              boxShadow: '0px 2px 10px rgba(0,0,0,0.40)', // Sombra suave
              borderRadius: '4px', // Bordes redondeados levemente
              padding: '16px', // Padding interno
              marginRight: '3px', // Margen derecho
              marginLeft: '20px', // Margen izquierdo
              marginBottom: '20px', // Margen inferior
              marginTop: '20px', // Margen superior
              backgroundColor: '#fff' // Fondo blanco
            }}>
            <Grid item xs={4}>
                <Field as={TextField} name={`Requerimientos[${index}].Requerimiento`} label="Requerimiento" fullWidth multiline  rows={4}/>
            </Grid>
            <Grid item xs={4}>
                <Field as={TextField} name={`Requerimientos[${index}].Responsable`} label="Responsable" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <Field as={TextField} name={`Requerimientos[${index}].Estatus`} label="Fecha compromiso" fullWidth />
            </Grid>
            <Grid item>
              { values.Requerimientos.length > 1 && (
                <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
              )}
                {index === values.Requerimientos.length - 1 && (

                <IconButton onClick={() => push({ idproyecto: id,  Requerimiento: '', Responsable: '', Estatus: '' })}><AddIcon /></IconButton>
                )}
                </Grid>
            </Grid>
        ))}
        </>
    )}
</FieldArray>
</Grid>


<Grid container spacing={2} className={classes.formContainer}>
<Grid item xs={12}>
<Typography variant="h6">Riesgos</Typography>
</Grid>
<FieldArray name="Riesgos">
    {({ push, remove }) => (
        <>
        {values.Riesgos.map((riesgo, index) => (
            <Grid key={index} item container spacing={2} alignItems="center"  style={{
              boxShadow: '0px 2px 10px rgba(0,0,0,0.40)', // Sombra suave
              borderRadius: '4px', // Bordes redondeados levemente
              padding: '16px', // Padding interno
              marginRight: '3px', // Margen derecho
              marginLeft: '20px', // Margen izquierdo
              marginBottom: '20px', // Margen inferior
              marginTop: '20px', // Margen superior
              backgroundColor: '#fff' // Fondo blanco
            }}>
            <Grid item xs={4}>
                <Field as={TextField} name={`Riesgos[${index}].Riesgo`} label="Riesgo" fullWidth multiline  rows={3}/>
            </Grid>
            <Grid item xs={4}>
                <Field as={TextField} name={`Riesgos[${index}].Verde`} label="Verde" fullWidth multiline  rows={3}/>
            </Grid>
            <Grid item xs={4}>
                <Field as={TextField} name={`Riesgos[${index}].Amarillo`} label="Amarillo" fullWidth multiline  rows={3}/>
            </Grid>
            <Grid item xs={4}>
                <Field as={TextField} name={`Riesgos[${index}].Rojo`} label="Rojo" fullWidth multiline  rows={3}/>
            </Grid>
            <Grid item>
                { values.Riesgos.length > 1 && (
                <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
                )}
                {index === values.Riesgos.length - 1 && (
                <IconButton onClick={() => push({ idproyecto: id, Riesgo: '', Verde: '', Amarillo: '', Rojo: '' })}><AddIcon /></IconButton>
                )}
                </Grid>
            </Grid>
        ))}
        </>
    )}
</FieldArray>
</Grid>

<Grid container spacing={2} className={classes.formContainer}>
<Grid item xs={12}>
<Typography variant="h6">Validaciones</Typography>
</Grid>
<FieldArray name="Validaciones">
    {({ push, remove }) => (
        <>
        {values.Validaciones.map((validacion, index) => (
            <Grid key={index} item container spacing={2} alignItems="center"  style={{
              boxShadow: '0px 2px 10px rgba(0,0,0,0.40)', // Sombra suave
              borderRadius: '4px', // Bordes redondeados levemente
              padding: '16px', // Padding interno
              marginRight: '3px', // Margen derecho
              marginLeft: '20px', // Margen izquierdo
              marginBottom: '20px', // Margen inferior
              marginTop: '20px', // Margen superior
              backgroundColor: '#fff' // Fondo blanco
            }}>
            <Grid item xs={4}>
                <Field as={TextField} name={`Validaciones[${index}].Funciones`} label="Funciones" fullWidth />
            </Grid>
            <Grid item xs={4}>
                <Field as={TextField} name={`Validaciones[${index}].Procedimiento`} label="Procedimiento" fullWidth multiline  rows={3} />
            </Grid>
            <Grid item>

                { values.Validaciones.length > 1 && (
                <IconButton onClick={() => remove(index)}><RemoveIcon /></IconButton>
                )}
                {index === values.Validaciones.length - 1 && (
                <IconButton onClick={() => push({ idproyecto: id, Funciones: '', Procedimiento: '' })}><AddIcon /></IconButton>
                )}
                </Grid>
            </Grid>
        ))}
        </>
    )}
</FieldArray>
</Grid>



<Grid container spacing={2} className={classes.formContainer}>
  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>  
    <Button
      variant="contained"
      color="primary"
      // startIcon={<SaveIcon />}
      onClick={() => handleSubmit(values)}
      style={{ backgroundColor: "#1976d2", color: "white", margin: "0 auto" }}  // Centrado horizontal con margen automático
    >
      Guardar Proyecto
    </Button>
  </Grid>

  <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}>  
    <Button
      variant="contained"
      color="secondary"
      startIcon={<PrintIcon />}
      onClick={handleGenerateDocument}
      style={{ backgroundColor: "#d32f2f", color: "white", margin: "0 auto" }}  // Centrado horizontal con margen automático
    >
      Generar Documento
    </Button>
  </Grid>
</Grid>
<br />


            </Form>
          )}
        </Formik>
        </Grid>
  </Grid>



      );

};

export default SowForm;
