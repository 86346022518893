import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Container, TextField, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Typography, Grid, Box, Modal, Stack, IconButton, CardHeader } from '@mui/material';
import { styled } from '@mui/system';
import MoreVertIcon from '@mui/icons-material/MoreVert'; 
import { useAuth0 } from "@auth0/auth0-react";
import AvisoLogin from '../Components/AvisoLogin';
import MyDrawerComponent from '../Components/NavBar';
import { GetAllPostMortem, PostPostMortem, UpdatePostMortem } from '../APIS/PostMortem';
import { GetAllProyectos } from '../APIS/proyectos';
import { PostNotificacion } from '../APIS/Notificaciones';

const PostMortemButton = styled('button')(({ theme }) => ({
  color: 'white',
  backgroundColor: '#0067A0',
  padding: theme.spacing(1, 2),
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#303f9f',
  },
}));

const getColorByStatus = (status) => {
  switch (status) {
    case 'Aprobado':
      return '#2196f3'; // Verde
      case 'aprobado':
        return '#2196f3'; // Verde
    case 'Cancelado':
      return '#f44336'; // Rojo
    case 'Revisión':
      return '#ffffff'; // Gris

    case 'revisión':
      return '#ffffff'; // Gris
    case 'Completado':
      return '#4caf50'; // Azul
    default:
      return '#ffffff'; // Blanco por defecto
  }
};

const PostMortem = () => {
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [selectedPostMortem, setSelectedPostMortem] = useState(null);
  const { id } = useParams();
  const [filtroCodigo, setFiltroCodigo] = useState(id || '');
  const [filtroEstado, setFiltroEstado] = useState('');
  const [filtroPorUsuario, setFiltroPorUsuario] = useState(''); 
  const [openModal, setOpenModal] = useState(false);
  const [modalMode, setModalMode] = useState(null); 
  const [filtroFecha, setFiltroFecha] = useState('');
  const [newPostMortem, setNewPostMortem] = useState({
    IDProyecto: '', 
    Acuerdo: '',
    Responsable: '',
    FechaPropuestaCompromiso: '',
    Status: ''
  });
  const [isAscending, setIsAscending] = useState(false); // Estado para controlar el orden
  const estadosPosibles = ["Aprobado", "Cancelado", "Revisión", "Completado"];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const jsonData = await GetAllPostMortem(id);
        // Ordenar la data por el campo "Codigo" en orden descendente por defecto
        const sortedData = jsonData.sort((a, b) => b.Proyecto.Codigo.localeCompare(a.Proyecto.Codigo));
        setData(sortedData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const fetchProyectos = async () => {
      try {
        const jsonData = await GetAllProyectos();
        setData2(jsonData);
        console.log(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    fetchProyectos();
  }, [id]);

  const handleOpenModal = (mode, postMortem = null) => {
    if (mode === 'edit' && postMortem) {
      setNewPostMortem({
        IDProyecto: postMortem.IDProyecto, 
        Acuerdo: postMortem.Acuerdo,
        Responsable: postMortem.Responsable,
        FechaPropuestaCompromiso: postMortem.FechaPropuestaCompromiso.split('T')[0], // Ajuste para el formato de fecha
        Status: postMortem.Status,
      });
      setSelectedPostMortem(postMortem);
    }
    setModalMode(mode);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalMode(null);
    setSelectedPostMortem(null);
    setNewPostMortem({
      IDProyecto: '', 
      Acuerdo: '',
      Responsable: '',
      FechaPropuestaCompromiso: '',
      Status: ''
    });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNewPostMortem(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSendNotification = async (PostMortem) => {
    try {
      const notificacion = {
        Usuario: PostMortem.Responsable,
        Notificacion: `Se ha creado un nuevo Post Mortem con estatus de: "${PostMortem.Status}" del cual eres responsable`,
        Visto: "false"
      };
      await PostNotificacion(notificacion);
      window.location.reload();
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleSendNotificationUpdate = async (estatus) => {
    try {
      const notificacion = {
        Usuario: selectedPostMortem.Responsable,
        Notificacion: `Se ha actualizado el estatus del Post Mortem para el proyecto ${selectedPostMortem.Proyecto.NombreProyecto} con el estatus ${estatus}`,
        Visto: "false"
      };
      await PostNotificacion(notificacion);
    } catch (error) {
      console.error('Error sending notification:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      if (modalMode === 'create') {
        if (newPostMortem.IDProyecto && newPostMortem.Acuerdo && newPostMortem.Responsable && newPostMortem.FechaPropuestaCompromiso && newPostMortem.Status) {
          await PostPostMortem(newPostMortem);
          await handleSendNotification(newPostMortem);
          window.location.reload();
        } else {
          alert('Por favor llene todos los campos');
        }
      } else if (modalMode === 'edit') {
        if (newPostMortem.IDProyecto && newPostMortem.Acuerdo && newPostMortem.Responsable && newPostMortem.FechaPropuestaCompromiso && newPostMortem.Status) {
          await UpdatePostMortem({ ...newPostMortem, id: selectedPostMortem.id });
          setData(data.map(item => item.id === selectedPostMortem.id ? { ...item, ...newPostMortem } : item));
          handleCloseModal();
          handleSendNotificationUpdate(newPostMortem.Status);
          window.location.reload();
        } else {
          alert('Por favor llene todos los campos');
        }
      }
    } catch (error) {
      console.error('Error submitting Post Mortem:', error);
    }
  };

  const handleToggleOrder = () => {
    setIsAscending(prevIsAscending => !prevIsAscending);
    setData(prevData => [...prevData].sort((a, b) => {
      return isAscending 
        ? b.Proyecto.Codigo.localeCompare(a.Proyecto.Codigo) 
        : a.Proyecto.Codigo.localeCompare(b.Proyecto.Codigo);
    }));
  };

  const datosFiltrados = data.filter(item => {
    const coincideCodigo = filtroCodigo ? item.Proyecto.Codigo.includes(filtroCodigo) : true;
    const coincideEstado = filtroEstado ? item.Status === filtroEstado : true;
    const coincideFecha = filtroFecha ? item.createdAt.includes(filtroFecha.slice(0, 10)) : true;
    const coincideUsuario = filtroPorUsuario ? item.Responsable.includes(filtroPorUsuario) : true;
    return coincideCodigo && coincideEstado  && coincideFecha && coincideUsuario;
  });

  const handleAsingarNombrePorCorreo = (correo) => {

    switch (correo) {

      case "eduardiniperejili@gmail.com":
        return "Eduardo Hernandez";
        
      case "eduardo.hernandez@compucad.com.mx":
        return "Eduardo Hernandez";
      case "okaypardo@gmail.com":
        return "Abraham Pardo";
      case "mariaheribe.dg@gmail.com":
        return "Maria Hernández";
      case "Minkas1452@gmail.com":
        return "Sergio Castillo";
      case "espinozamares5@gmail.com":
        return "Luis Espinoza";
      case "adrian.cuellarf@gmail.com":
        return "Adrian Cuellar";
        case "adrian.cuellar@compucad.com.mx":
          return "Adrian Cuellar";
        case "gerardo.guzman@compucad.com.mx":
          return "Gerardo Guzman";
        case "juanpablomen2002@gmail.com":
          return "Pablo Mendoza";
        case "diegomartinezcortes2002@gmail.com":
          return "Diego Martinez";
        case "NA":
        return "Sin asignar";
      
      default: return correo; 
    }
  }

  return (
    <div>
      {useAuth0().isAuthenticated ? (
        <div>
          <MyDrawerComponent pageName="Post Mortem" selectedMenuItem="Post Mortem" />
          <Container>
            <Box sx={{ my: 4, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h4" component="h1" gutterBottom align="center">
                Revisión Post Mortem
              </Typography>
              <Grid container spacing={2} justifyContent="center">
                <Grid item>
                  <TextField
                    label="Filtrar por Código"
                    variant="outlined"
                    size="small"
                    value={filtroCodigo}
                    onChange={e => setFiltroCodigo(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <FormControl size="small" sx={{ minWidth: 240 }}>
                    <InputLabel>Estado</InputLabel>
                    <Select
                      value={filtroEstado}
                      label="Estado"
                      onChange={e => setFiltroEstado(e.target.value)}
                    >
                      <MenuItem value="" style={{width:'100%'}}>
                        <em>Todos los Estados</em>
                      </MenuItem>
                      {estadosPosibles.map(estado => (
                        <MenuItem style={{width:'100%'}} key={estado} value={estado}>{estado}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item>
                  <TextField
                    label="Filtrar por Usuario"
                    variant="outlined"
                    size="small"
                    value={filtroPorUsuario}
                    onChange={e => setFiltroPorUsuario(e.target.value)}
                  />
                </Grid>
                <Grid item>
                  <PostMortemButton onClick={handleToggleOrder}>
                    Ordenar {isAscending ? 'Descendente' : 'Ascendente'}
                  </PostMortemButton>
                </Grid>
                <Grid item>
                  <PostMortemButton onClick={() => handleOpenModal('create')}>Agregar Post Mortem</PostMortemButton>
                </Grid>
              </Grid>
            </Box>
            <Grid container spacing={4} justifyContent="center">
              {datosFiltrados.map(item => (
                <Grid item key={item.id} xs={12} sm={6} md={4} lg={3}>
                  <Card sx={{ maxWidth: 345, bgcolor: getColorByStatus(item.Status) }}>
                    <CardHeader
                      action={
                        <IconButton aria-label="settings" onClick={(e) => handleOpenModal('edit', item)}>
                          <MoreVertIcon />
                        </IconButton>
                      }
                      title={item.Proyecto.NombreProyecto}
                      subheader={`Código: ${item.Proyecto.Codigo}`}
                    />
                    <CardContent>
                      <Typography variant="body2">
                        <strong>Acuerdo: </strong> {item.Acuerdo}
                        <br />
                        <strong>Responsable: </strong>{handleAsingarNombrePorCorreo(item.Responsable)}
                        <br />
                        <strong>Fecha limite: </strong> {item.FechaPropuestaCompromiso ? new Date(item.FechaPropuestaCompromiso).toISOString().split('T')[0] : ''}
                        <br />
                        <strong>Estado: </strong> {item.Status}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {modalMode === 'edit' ? 'Editar Post Mortem' : 'Crear nuevo Post Mortem'}
                  {selectedPostMortem && <Typography variant="h6" component="h2">Proyecto: {selectedPostMortem.Proyecto.NombreProyecto}</Typography>}
                </Typography>
                <Stack spacing={2} sx={{ mt: 2 }}>
                  <FormControl fullWidth>
                    <InputLabel id="proyecto-select-label">Código de Proyecto</InputLabel>
                    <Select
                      labelId="proyecto-select-label"
                      id="proyecto-select"
                      value={newPostMortem.IDProyecto}
                      label="Código de Proyecto"
                      name="IDProyecto"
                      onChange={handleChange}
                    >
                      {data2.map(proyecto => (
                        
                        <MenuItem style={{ width: '100%' }}  key={proyecto.id} value={proyecto.id}> {`${proyecto.Codigo} - ${proyecto.NombreProyecto}`}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <TextField
                    label="Acuerdo"
                    name="Acuerdo"
                    value={newPostMortem.Acuerdo}
                    onChange={handleChange}
                    fullWidth
                  />
                  <FormControl fullWidth>
                    <InputLabel id="responsable-select-label">Responsable</InputLabel>
                    <Select
                      labelId="responsable-select-label"
                      id="responsable-select"
                      value={newPostMortem.Responsable}
                      label="Responsable"
                      name="Responsable"
                      onChange={handleChange}
                    >
                      <MenuItem value="okaypardo@gmail.com"  style={{ width: '100%' }}>Abraham Pardo</MenuItem>
                      <MenuItem value="mariaheribe.dg@gmail.com" style={{ width: '100%' }}>Maria Hernández</MenuItem>
                      <MenuItem value="Minkas1452@gmail.com" style={{ width: '100%' }}>Sergio Castillo</MenuItem>
                      <MenuItem value="eduardiniperejili@gmail.com" style={{ width: '100%' }}>Eduardo Hernandez</MenuItem>
                      <MenuItem value="espinozamares5@gmail.com" style={{ width: '100%' }}>Luis Espinoza</MenuItem>
                      <MenuItem value="adrian.cuellarf@gmail.com" style={{ width: '100%' }}>Adrian Cuellar</MenuItem>
                      <MenuItem value="juanpablomen2002@gmail.com" style={{ width: '100%' }}> Pablo Mendoza</MenuItem>
                      <MenuItem value="diegomartinezcortes2002@gmail.com" style={{ width: '100%' }}>Diego Martinez</MenuItem>
                    </Select>
                  </FormControl>
                  <TextField
                    label="Fecha Propuesta de Compromiso"
                    name="FechaPropuestaCompromiso"
                    type="date"
                    value={newPostMortem.FechaPropuestaCompromiso}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  <FormControl fullWidth>
                    <InputLabel id="estado-select-label">Estado</InputLabel>
                    <Select
                      labelId="estado-select-label"
                      id="estado-select"
                      value={newPostMortem.Status}
                      label="Estado"
                      name="Status"
                      onChange={handleChange}
                    >
                      {estadosPosibles.map(estado => (
                        <MenuItem style={{ width: '100%' }} key={estado} value={estado}>{estado}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <PostMortemButton onClick={handleSubmit}>Guardar</PostMortemButton>
                </Stack>
              </Box>
            </Modal>
          </Container>
          <br />
          <br />
        </div>
      ) : (
        <AvisoLogin />
      )}
    </div>
  );
};

export default PostMortem;
