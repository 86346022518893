import React, { useState, useEffect } from 'react';
import { Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, IconButton, TextField, Button, Select, MenuItem, Box, Pagination, Grid, Paper } from '@mui/material';
import { CheckCircleOutline, HighlightOff, Chat, ArrowCircleRight, ArrowBack } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import MyDrawerComponent from '../Components/NavBar';
import { GetAllSolicitudes } from '../APIS/Solicitudes';
import AvisoLogin from '../Components/AvisoLogin';
import { useAuth0 } from "@auth0/auth0-react";
import { SendTeamsAlertProyecto } from '../APIS/proyectos';

const Solicitudes = () => {
  const [data, setData] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
  const [prioritySortOrder, setPrioritySortOrder] = useState('asc'); // 'asc' for ascending, 'desc' for descending
  const [estatusFilter, setEstatusFilter] = useState('');
  const [unidadNegocioFilter, setUnidadNegocioFilter] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(10);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const jsonData = await GetAllSolicitudes();
        setData(jsonData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataFromApi();
  }, []);

  const handleFilterChange = (event) => {
    setFilterText(event.target.value);
    setPage(1); // Reset page when changing filters
  };

  const handleSortToggle = () => {
    setSortOrder(sortOrder === 'desc' ? 'asc' : 'desc');
  };

  const handlePrioritySortToggle = () => {
    setPrioritySortOrder(prioritySortOrder === 'desc' ? 'asc' : 'desc');
  };

  const handleEstatusFilterChange = (event) => {
    setEstatusFilter(event.target.value);
    setPage(1); // Reset page when changing filters
  };

  const handleUnidadNegocioFilterChange = (event) => {
    setUnidadNegocioFilter(event.target.value);
    setPage(1); // Reset page when changing filters
  };

  const filteredData = data ? data.filter(item => {
    return (
      ((item.Idea && item.Idea.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.Folio && item.Folio.toLowerCase().includes(filterText.toLowerCase())) ||
      (item.solicitante && item.solicitante.Nombre.toLowerCase().includes(filterText.toLowerCase()))) &&
      (estatusFilter === '' || item.Status.toLowerCase() === estatusFilter.toLowerCase()) &&
      (unidadNegocioFilter === '' || item.UN.toLowerCase() === unidadNegocioFilter.toLowerCase())
    );
  }) : [];
  
  const sortedData = filteredData ? filteredData.sort((a, b) => {
    if (sortOrder === 'asc') {
      return b.FechaRegistro.localeCompare(a.FechaRegistro);
    } else {
      return a.FechaRegistro.localeCompare(b.FechaRegistro);
    }
  }).sort((a, b) => {
    if (estatusFilter === 'En revisión') {
      if (prioritySortOrder === 'asc') {
        return a.ResultadoMatrizPrioridad - b.ResultadoMatrizPrioridad;
      } else {
        return b.ResultadoMatrizPrioridad - a.ResultadoMatrizPrioridad;
      }
    }
    return 0; // No cambiar el orden si el estatus no es 'En revisión'
  }) : [];

  const estatusOptions = ['En revisión','Aceptado','Rechazado'];

  const unidadNegocioOptions = [
    'ADMINISTRACIÓN',
    'ADOBE',
    'AUTODESK',
    'CAD',
    'CDH',
    'COMPUCLOUD',
    'DIRECCIÓN',
    'GOBIERNO',
    'GT',
    'INFRAESTRUCTURA',
    'MICROSOFT',
    'MKT',
    'MOVILIDAD Y SEGURIDAD',
    'OPERACIONES',
    'SAI',
    'SERVICIOS PROFESIONALES',
    'TRANSFORMACIÓN DIGITAL',
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const indexOfLastRow = page * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedData.slice(indexOfFirstRow, indexOfLastRow);

  return (
    <div>
      { useAuth0().isAuthenticated ? (
        <div>
          <MyDrawerComponent pageName="Solicitudes" selectedMenuItem="Solicitudes" />
          <br />
          <div style={{ maxWidth: '1200px', margin: '0 auto', padding: '20px' }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6} lg={3}>
                <TextField
                  label="Filtrar por idea o correo de solicitante"
                  variant="outlined"
                  value={filterText}
                  onChange={handleFilterChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Select
                  value={estatusFilter}
                  onChange={handleEstatusFilterChange}
                  displayEmpty
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="" style={{ width: '100%' }}>Filtrar por Estatus</MenuItem>
                  {estatusOptions.map((estatus, index) => (
                    <MenuItem style={{ width: '100%' }} key={index} value={estatus}>{estatus}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Select
                  value={unidadNegocioFilter}
                  onChange={handleUnidadNegocioFilterChange}
                  displayEmpty
                  variant="outlined"
                  fullWidth
                >
                  <MenuItem value="" style={{ width: '100%' }}>Filtrar por Unidad de Negocio</MenuItem>
                  {unidadNegocioOptions.map((unidadNegocio, index) => (
                    <MenuItem key={index} style={{ width: '100%' }} value={unidadNegocio}>{unidadNegocio}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <Button
                  variant="outlined"
                  onClick={handleSortToggle}
                  fullWidth
                  style={{ color: '#fff', backgroundColor: '#1976d2', borderColor: '#1976d2', marginBottom: '20px' }}
                >
                  {sortOrder === 'asc' ? 'Ordenar por fecha más viejo a más reciente' : 'Ordenar por fecha más reciente a más viejo'}
                </Button>
              </Grid>
              {estatusFilter === 'En revisión' && (
                <Grid item xs={12} md={6} lg={3}>
                  <Button
                    variant="outlined"
                    onClick={handlePrioritySortToggle}
                    fullWidth
                    style={{ color: '#fff', backgroundColor: '#1976d2', borderColor: '#1976d2', marginBottom: '20px' }}
                  >
                    {prioritySortOrder === 'asc' ? 'Ordenar por prioridad más baja a más alta' : 'Ordenar por prioridad más alta a más baja'}
                  </Button>
                </Grid>
              )}
            </Grid>
            <br />
            {currentRows.length > 0 ? (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow style={{ backgroundColor: '#0067A0', color: "#ffffff" }}>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Fecha solicitud</TableCell>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Idea</TableCell>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Prioridad</TableCell>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Solicitante</TableCell>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>UN</TableCell>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Clasificación</TableCell>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Status</TableCell>
                      <TableCell align="center" style={{ color: 'white', fontWeight: 'bold' }}>Detalles</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {currentRows.map((item, index) => (
                      <TableRow
                        key={item.id}
                        style={{ backgroundColor: index % 2 === 0 ? '#e3f2fd' : '#ffffff' }}
                      >
                        <TableCell align="center">{new Date(item.FechaRegistro).toISOString().split('T')[0]}</TableCell>
                        <TableCell align="center">{item.Idea}</TableCell>
                        <TableCell align="center">
                          {item.ResultadoMatrizPrioridad !== 0 && item.ResultadoMatrizPrioridad ? item.ResultadoMatrizPrioridad : "Sin asignar"}
                        </TableCell>
                        <TableCell align="center">{item.solicitante.Nombre}</TableCell>
                        <TableCell align="center">{item.UN}</TableCell>
                        <TableCell align="center">{item.Clasificacion}</TableCell>
                        <TableCell align="center">{item.Status}</TableCell>
                        <TableCell align="center">
                          <IconButton component={Link} to={`/detalles-solicitud/${item.id}`}>
                            <ArrowCircleRight />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <Box mt={4} display="flex" justifyContent="center" alignItems="center">
                <Paper elevation={3} style={{ padding: '20px', backgroundColor: 'white', color: 'gray', textAlign: 'center' }}>
                  <Typography variant="h6">No se encontraron solicitudes que coincidan con el filtro.</Typography>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                      src="https://images.vexels.com/media/users/3/152639/isolated/preview/506b575739e90613428cdb399175e2c8-dibujos-animados-de-astronauta-espacial.png"
                      alt="404"
                      style={{ width: '200px', height: '200px' }}
                    />
                  </div>
                </Paper>
              </Box>
            )}
            <Box display="flex" justifyContent="center" marginTop="20px">
              <Pagination
                count={Math.ceil(sortedData.length / rowsPerPage)}
                page={page}
                onChange={handleChangePage}
                variant="outlined"
                shape="rounded"
              />
            </Box>
          </div>
        </div>
      ) : (
        <AvisoLogin />
      )}
    </div>
  );
};

export default Solicitudes;
