import React, { useState, useEffect } from 'react';
import { GetAllMatrizCambios, CreateMatrizCambios, deleteElementCambios } from '../APIS/Matriz';
import { Container, TextField, Select, MenuItem, Button, Grid, Typography, Card, CardContent, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DeleteIcon from '@mui/icons-material/Delete';

const options = {
  Impacto: [
    { label: 'Alto: Afecta significativamente al proceso', value: 5 },
    { label: 'Medio: Afecta a algunos puntos del proceso', value: 3 },
    { label: 'Bajo: No afecta al proceso', value: 1 }
  ],
  Complejidad: [
    { label: 'Alta: Requiere cambios significativos en el código, infraestructura o arquitectura', value: 5 },
    { label: 'Media: Requiere cambios moderados en el código o configuración', value: 3 },
    { label: 'Baja: Requiere cambios mínimos', value: 1 }
  ],
  Duracion: [
    { label: 'Larga: Más de 20 horas', value: 5 },
    { label: 'Media: Entre 10 y 20 horas', value: 3 },
    { label: 'Corta: Menos de 10 horas', value: 1 }
  ],
  Costo: [
    { label: 'Alto: Costoso', value: 5 },
    { label: 'Medio: Coste moderado', value: 3 },
    { label: 'Bajo: Coste mínimo', value: 1 }
  ],
  Recursos: [
    { label: 'Alto: Requiere pausar proyectos', value: 5 },
    { label: 'Medio: Requiere destinarle tiempo exclusivo al proyecto', value: 3 },
    { label: 'Bajo: Requiere el tiempo entre proyectos de un desarrollador', value: 1 }
  ],
  Riesgo: [
    { label: 'Alto: Alta probabilidad de problemas o fallos', value: 5 },
    { label: 'Medio: Moderada probabilidad de problemas', value: 3 },
    { label: 'Bajo: Baja probabilidad de problemas', value: 1 }
  ],
  Beneficio: [
    { label: 'Alto: Gran mejora en eficiencia, ahorro de costos o satisfacción', value: 1 },
    { label: 'Medio: Mejoras moderadas', value: 3 },
    { label: 'Bajo: Mejoras mínimas', value: 5 }
  ],
  Urgencia: [
    { label: 'Alta: Necesidad inmediata', value: 1 },
    { label: 'Media: Necesidad en el corto plazo', value: 3 },
    { label: 'Baja: No es urgente', value: 5 }
  ],
  Dependencia: [
    { label: 'Alta: Muchas dependencias y necesidad de coordinación con múltiples equipos o unidades de negocio', value: 5 },
    { label: 'Media: Algunas dependencias y coordinación moderada con algunas personas', value: 3 },
    { label: 'Baja: Pocas o ninguna dependencia, depende totalmente del desarrollador', value: 1 }
  ]
};

const getColor = (total) => {
  if (total > 80) return 'lightgreen';
  if (total > 60) return 'lightblue';
  return 'lightcoral';
};

const getOptionLabel = (key, value) => {
  const option = options[key].find(option => option.value === value);
  return option ? option.label : value;
};

const MatrizCambios = ({ idProyecto }) => {
  const [formData, setFormData] = useState({
    CodigoProyecto: idProyecto,
    Impacto: '',
    Complejidad: '',
    Duracion: '',
    Costo: '',
    Recursos: '',
    Riesgo: '',
    Beneficio: '',
    Urgencia: '',
    Dependencia: '',
    Comentarios1: '',
    Comentarios2: 'Sin comentarios',
    Total: 0
  });

  const [matrizCambios, setMatrizCambios] = useState([]);
  const [expanded, setExpanded] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await GetAllMatrizCambios();
        const Filtrados = data.filter((cambio) => cambio.CodigoProyecto === idProyecto);
        const Ordenados = Filtrados.sort((a, b) => b.Total - a.Total);
        setMatrizCambios(Ordenados);
      } catch (error) {
        console.error('Error fetching data', error);
      }
    };
    fetchData();
  }, [idProyecto]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const calculateTotal = () => {
    const {
      Impacto,
      Complejidad,
      Duracion,
      Costo,
      Recursos,
      Riesgo,
      Beneficio,
      Urgencia,
      Dependencia
    } = formData;
    const total = parseInt(Impacto) + parseInt(Complejidad) + parseInt(Duracion) + parseInt(Costo) + parseInt(Recursos) + parseInt(Riesgo) + parseInt(Beneficio) + parseInt(Urgencia) + parseInt(Dependencia);
    return total;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const total = calculateTotal();
    const updatedFormData = {
      ...formData,
      Total: total
    };
    try {
      // Valida que todos los campos estén llenos excepto los comentarios
      if (Object.values(updatedFormData).some(value => value === '')) {
        alert('Por favor llena todos los campos');
        return;
      }

      await CreateMatrizCambios(updatedFormData);
      alert('Datos enviados con éxito');
      setFormData({
        CodigoProyecto: idProyecto,
        Impacto: '',
        Complejidad: '',
        Duracion: '',
        Costo: '',
        Recursos: '',
        Riesgo: '',
        Beneficio: '',
        Urgencia: '',
        Dependencia: '',
        Comentarios1: '',
        Comentarios2: 'Sin comentarios',
        Total: 0
      });
      const updatedData = await GetAllMatrizCambios();
      
      const Filtrados = updatedData.filter((cambio) => cambio.CodigoProyecto === idProyecto);
      const Ordenados = Filtrados.sort((a, b) => b.Total - a.Total);
      setMatrizCambios(Ordenados);
   
    } catch (error) {
      console.error('Error creating Matriz Cambios', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await deleteElementCambios(id);
      const updatedData = await GetAllMatrizCambios();
      const Filtrados = updatedData.filter((cambio) => cambio.CodigoProyecto === idProyecto);
      const Ordenados = Filtrados.sort((a, b) => b.Total - a.Total);
      setMatrizCambios(Ordenados);
    } catch (error) {
      console.error('Error deleting Matriz Cambios', error);
    }
  };

  const handleExpandClick = (index) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [index]: !prevExpanded[index]
    }));
  };

  return (
    <Container>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="Cambio"
              name="Comentarios1"
              value={formData.Comentarios1}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              multiline
              rows={2}
              size="small"
            />
            <Grid container spacing={2}>
              {Object.keys(options).map((key) => (
                <Grid item xs={12} sm={6} key={key}>
                  <Typography variant="h6">{key === 'Costo' ? 'Costo operativo:' : `${key}:`}</Typography>
                  <Select
                    fullWidth
                    name={key}
                    value={formData[key]}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                  >
                    {options[key].map((option) => (
                      <MenuItem key={option.value} value={option.value} style={{ width: '100%' }}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              ))}
            </Grid>
            <TextField
              fullWidth
              label="Comentarios"
              name="Comentarios2"
              value={formData.Comentarios2}
              onChange={handleChange}
              margin="normal"
              variant="outlined"
              size="small"
            />
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button variant="contained" color="primary" type="submit" style={{ marginTop: '1rem', marginBottom: '1rem' }}>
                Enviar
              </Button>
            </div>
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h5" gutterBottom>Matriz de Cambios</Typography>
            {matrizCambios.map((cambio, index) => (
              <Card key={index} style={{ marginBottom: '1rem', boxShadow: '0 3px 5px 2px rgba(0, 0, 0, .1)', transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.05)' } }}>
                <CardContent style={{ backgroundColor: getColor((cambio.Total / 40) * 100), padding: '16px' }}>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs={10}>
                      <Typography variant="h6">Cambio: {cambio.Comentarios1.length > 40 ? `${cambio.Comentarios1.substring(0, 40)}...` : cambio.Comentarios1}</Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={() => handleExpandClick(index)}>
                        {expanded[index] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton onClick={() => handleDelete(cambio.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Typography variant="subtitle1">Total: {((cambio.Total / 45) * 100).toFixed(2)}%</Typography>
                </CardContent>
                <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                  <CardContent>
                    <Typography variant="body1" style={{ textAlign: 'center' }}><strong>Cambio:</strong> {cambio.Comentarios1}</Typography>
                    <br />
                    <Typography variant="body2"><strong>Impacto:</strong> {cambio.Impacto} - {getOptionLabel('Impacto', cambio.Impacto)}</Typography>
                    <Typography variant="body2"><strong>Complejidad:</strong> {cambio.Complejidad} - {getOptionLabel('Complejidad', cambio.Complejidad)}</Typography>
                    <Typography variant="body2"><strong>Duracion:</strong> {cambio.Duracion} - {getOptionLabel('Duracion', cambio.Duracion)}</Typography>
                    <Typography variant="body2"><strong>Costo operativo:</strong> {cambio.Costo} - {getOptionLabel('Costo', cambio.Costo)}</Typography>
                    <Typography variant="body2"><strong>Recursos:</strong> {cambio.Recursos} - {getOptionLabel('Recursos', cambio.Recursos)}</Typography>
                    <Typography variant="body2"><strong>Riesgo:</strong> {cambio.Riesgo} - {getOptionLabel('Riesgo', cambio.Riesgo)}</Typography>
                    <Typography variant="body2"><strong>Beneficio:</strong> {cambio.Beneficio} - {getOptionLabel('Beneficio', cambio.Beneficio)}</Typography>
                    <Typography variant="body2"><strong>Urgencia:</strong> {cambio.Urgencia} - {getOptionLabel('Urgencia', cambio.Urgencia)}</Typography>
                    <Typography variant="body2"><strong>Dependencia:</strong> {cambio.Dependencia} - {getOptionLabel('Dependencia', cambio.Dependencia)}</Typography>
                    <br />
                    <Typography variant="body2"><strong>Comentarios:</strong> {cambio.Comentarios2}</Typography>
                  </CardContent>
                </Collapse>
              </Card>
            ))}
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default MatrizCambios;
