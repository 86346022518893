import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
export const LogoutButton = () => {
    const { logout } = useAuth0();
    
    return (
        <Button
         
          onClick={() => logout({ returnTo: window.location.origin })}
          sx={{
            fontWeight: 'bold', // Texto en negrita
            boxShadow: 'none', // Sin sombra para un aspecto más limpio
            ':hover': {
              boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)', // Sombra al pasar el ratón por encima
            },
            padding: '10px 20px', // Ajusta el padding para cambiar el tamaño
            borderRadius: '20px', // Bordes redondeados
            color: 'black',
            textTransform: 'none', // Mantiene el texto como se define sin transformación a mayúsculas
          }}
        >
          Log Out
        </Button>
    );
    }