import React, { useEffect, useState } from "react";
import { IconButton, Badge, Button, List, ListItem, ListItemText } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { NotificacionesByUser,MarcarComoVisto } from "../APIS/Notificaciones";
import VisibilityIcon from '@material-ui/icons/Visibility';

const Notificaciones = ({ correo }) => {
    const [notificaciones, setNotificaciones] = useState([]);
    const [isOpen, setIsOpen] = useState(false);

    useEffect(() => {
        const cargarNotificaciones = async () => {
            console.log(correo);
            const data = await NotificacionesByUser(correo);
            setNotificaciones(data);
        };

        if (correo) {
            cargarNotificaciones();
        }
    }, [correo]);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    const marcarComoVisto = async (id) => {
        await MarcarComoVisto(id);
        const data = await NotificacionesByUser(correo);
        setNotificaciones(data); // Recarga las notificaciones para reflejar el cambio
    };
    
    return (
        <div>
            <IconButton onClick={toggleDropdown} color="inherit">
                <Badge badgeContent={notificaciones.length} color="secondary">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            {isOpen && (
          <List style={{ position: 'absolute', width: '400px', backgroundColor: 'white', boxShadow: '0px 2px 10px rgba(0,0,0,0.6)' }}>
          {notificaciones.length > 0 ? (
              notificaciones.map((notificacion) => (
                  <ListItem key={notificacion.id} style={{ borderBottom: '2px solid #D3D3D3' }}>
                      <ListItemText 
                          primary={notificacion.Notificacion} 
                          style={{ color: 'black' }}  // Gris oscuro para el texto
                      />
                      <IconButton 
                          onClick={() => marcarComoVisto(notificacion.id)} 
                          style={{ color: 'black' }}  // Gris claro para el icono
                      >
                          <VisibilityIcon />
                      </IconButton>
                  </ListItem>
              ))
          ) : (
              <ListItem>
                  <ListItemText 
                      primary="No hay notificaciones" 
                      style={{ color: '#424242' }}  // Gris oscuro también para el mensaje de "No hay"
                  />
              </ListItem>
          )}
      </List>
      
          
            )}
        </div>
    );
};

export default Notificaciones;
