import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from '@mui/material/Button';
import { blue } from '@mui/material/colors';

export const LoginButton = () => {
    const { loginWithRedirect } = useAuth0();

    return (
        <Button
            variant="contained"
            style={{
                backgroundColor: blue[600],
                color: 'white',
                padding: '10px 20px',
                textTransform: 'none',
                fontSize: '16px',
                fontWeight: 'bold',
                borderRadius: '5px',
                boxShadow: '0px 2px 10px rgba(0,0,0,0.1)',
                transition: 'background-color 0.3s',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: blue[800],
                },
            }}
            onClick={() => loginWithRedirect()}
        >
            Log In
        </Button>
    );
}
