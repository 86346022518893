import React, { useState,useMemo,useEffect } from 'react';
import { Button, TextField, Typography, Box, Container, Grid, Select, MenuItem, InputLabel, FormControl, FormGroup, FormControlLabel, Checkbox,Modal } from '@mui/material';
import { SendSolicitud } from '../APIS/Solicitudes';
import ParticlesComponent from '../Components/ParticlesComponent'; 

const RegistrarIdea = () => {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
      };
    const [idea, setIdea] = useState('');
    const [descripcion, setDescripcion] = useState('');
    const [un, setUn] = useState('');
    const [clasificacion, setClasificacion] = useState([]);
    const [impacto, setImpacto] = useState('');
    const [nombre, setNombre] = useState('');
    const [correo, setCorreo] = useState('');
    const [folio, setFolio] = useState('');
    const [TieneProceso, setTieneProceso] = useState('');
    const unidadesNegocio = ["ADMINISTRACIÓN", "ADOBE", "AUTODESK", "CDH", "COMPUCLOUD", "DIRECCIÓN", "GT", "GOBIERNO", "INFRAESTRUCTURA", "MICROSOFT", "MOVILIDAD Y SEGURIDAD", "SAI", "TRANSFORMACIÓN DIGITAL", "OPERACIONES"];
    const impactos = ["Área", "Varias Áreas", "Toda la empresa", "Un proceso"];
    const clasificaciones = ["Automatización", "Innovación", "IA", "RPA", "Nuevo modulo en portal compucad"];
    let fechaRegistro = new Date().toISOString().slice(0, 10); 
    const handleClasificacionChange = (event) => {
        const { name, checked } = event.target;
        if (checked) {
            setClasificacion([...clasificacion, name]);
        } else {
            setClasificacion(clasificacion.filter(item => item !== name));
        }
    };
    
  const [openModal, setOpenModal] = useState(false);

  const handleCloseModal = () => setOpenModal(false);
    const clasificacionString = clasificacion.join(', ');
    useEffect(() => {
         fechaRegistro = new Date().toISOString().slice(0, 10); 
        setFolio(`CCADI${Math.floor(100 + Math.random() * 900)}-${fechaRegistro}`);
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        let sponsorEmail = '';
        switch (un) {
            case "ADMINISTRACIÓN":
              sponsorEmail = "martha.vazquez@compucad.com.mx";
              break;
            case "ADOBE":
              sponsorEmail = "hugo.hernandez@compucad.com.mx";
              break;
            case "AUTODESK":
              sponsorEmail = "laura.paredes@compucad.com.mx";
              break;
            case "CDH":
              sponsorEmail = "felipe.ochoa@compucad.com.mx";
              break;
            case "COMPUCLOUD":
              sponsorEmail = "oswaldo.luna@compucloud.com.mx";
              break;
            case "DIRECCIÓN":
              sponsorEmail = "juanc.gonzalez@compucad.com.mx";
              break;
            case "GT":
              sponsorEmail = "gricelda.santana@compucad.com.mx";
              break;
            case "GOBIERNO":
              sponsorEmail = "jaime.rodriguez@compucad.com.mx";
              break;
            case "MKT":
              sponsorEmail = "raul.benhumer@compucad.com.mx";
              break;
            case "MICROSOFT":
              sponsorEmail = "juan.panas@compucad.com.mx";
              break;
            case "OPERACIONES":
              sponsorEmail = "mauricio.islas@compucad.com.mx";
              break;
            case "SAI":
              sponsorEmail = "fernando.jimenez@compucad.com.mx";
              break;
            case "SEGURIDAD Y MOVILIDAD":
              sponsorEmail = "fernando.jimenez@compucad.com.mx";
              break;
            case "SP":
              sponsorEmail = "uriel.martinez@compucad.com.mx";
              break;
            case "STAFF DIRECCIÓN":
              sponsorEmail = "juanc.gonzalez@compucad.com.mx";
              break;
            case "TRANSFORMACIÓN DIGITAL":
              sponsorEmail = "juan.panas@compucad.com.mx";
              break;
            case "INFRAESTRUCTURA":
              sponsorEmail = "raul.camara@compucad.com.mx";
              break;
            default:
              sponsorEmail = 'Unidad no encontrada';
              break;
          }
        const solicitud = {
            Solicitud: {
                Correo: correo+'@compucad.com.mx',
                FechaRegistro: fechaRegistro,
                Idea: idea,
                TieneProceso:TieneProceso,
                Descripción: descripcion,
                UN: un,
                Clasificacion: clasificacion.join(', '), 
                Impacto: impacto,
                Folio: folio,
                Status: "En revisión"
            },
            Solicitante: {
                Nombre: nombre,
                Correo: correo+'@compucad.com.mx',
                UN: un
            }
        };
        const solicitud2 = {
            Solicitud: {
                Correo: correo+'@compucad.com.mx',
                FechaRegistro: fechaRegistro,
                Idea: idea,
                TieneProceso:TieneProceso,
                Descripción: descripcion,
                UN: un,
                Clasificacion: clasificacion.join(', '), 
                Impacto: impacto,
                Folio: folio,
                Status: "En revisión",
                Director: sponsorEmail
            },
            Solicitante: {
                Nombre: nombre,
                Correo: correo+'@compucad.com.mx',
                UN: un
            }
        };
console.log(solicitud);
        try {
           if (idea.length > 0 && descripcion.length > 0 && un.length > 0 && impacto.length > 0 && nombre.length > 0 && correo.length > 0 && clasificacion.length > 0 && TieneProceso.length > 0) {
           await SendSolicitud(solicitud);
              
           console.log(solicitud);

           const response = await fetch('https://prod-17.westus.logic.azure.com:443/workflows/f9ade3e0c955457d8347956e9338884b/triggers/manual/paths/invoke?api-version=2016-06-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=C3J6O3m-IGIo5gknFuYPuv3tDngOUgfnIF0mDQ6ZbS8', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(solicitud2),
        });

            setOpenModal(true);
            //borra los campos
            setIdea('');
            setDescripcion('');
            setUn('');
            setClasificacion([]);
            setImpacto('');
            setNombre('');
            setCorreo('');
            setTieneProceso('');
            
           }
           else{
               alert('Todos los campos son requeridos');
           }
            
        } catch (error) {
            console.error('Error al enviar la solicitud:', error);
            alert('Error al enviar la solicitud');
        }
    };
    const particlesComponentMemo = useMemo(() => <ParticlesComponent />, []);

    return (
        <div  style={{ position: 'relative' }}>
            
            {particlesComponentMemo}

<div style={{ position: 'relative', zIndex: 2 }}>
  
        <Container maxWidth="sm"      sx={{
                mt: 0,
                bgcolor: 'rgba(255, 255, 255, 0.9)', // Fondo blanco con opacidad
                backdropFilter: 'blur(2px)', // Efecto de desenfoque para el efecto de cristal
                boxShadow: 1, // Añade una sombra para resaltar la tarjeta
                borderRadius: 2, // Bordes redondeados para un aspecto más suave
                p: 2, // Padding interior para separar el contenido del borde de la tarjeta
              }}>
        <Box 
 
 >

    <img src="https://static.wixstatic.com/media/9c7b90_f4b51a4cfbf94766baae2af4cc2a3f02~mv2.png" alt="logo" style={{ width: '100px', height: '50px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }} />

   <Typography
     variant="h4"
     component="div"
     sx={{
       textAlign: 'center', 
       fontWeight: 'bold', 
       marginTop: 4, 
       marginBottom: 4,
     }}
   >
     Estás a punto de unirte a la transformación digital
   </Typography>
 </Box>
<Box sx={{ textAlign: 'center', mt: 2, mb: 4 }}>
  <Typography
    variant="subtitle1"
    sx={{
      color: '#424242', // Ajusta el color según tu esquema de diseño
      marginBottom: 2, // Espacio entre este texto y el siguiente
    }}
  >
    Al registrar una idea ayudas con la mejora de los procesos, potenciando el talento tuyo y de todos los colaboradores.
  </Typography>
  <Typography
    variant="subtitle1"
    sx={{
      color: '#424242', // Ajusta el color para que coincida o complemente el anterior
      fontStyle: 'italic', // Estilo de fuente en cursiva para destacar esta parte
    }}
  >
    Aportar tu idea te tomará solo 5 minutos.
  </Typography>
<br />
  <Typography
    variant="subtitle1"
    sx={{
      color: 'red', // Ajusta el color para que coincida o complemente el anterior
      fontStyle: 'italic', // Estilo de fuente en cursiva para destacar esta parte
    }}
  >
 IMPORTANTE: Describe tu idea de forma clara y concisa, recuerda que la información que proporciones será clave para la evaluación de tu idea, por lo que te recomendamos ser lo más detallado posible. Las ideas con mas prioridad y valor serán las que tengan mayor impacto en la empresa.
  </Typography>
</Box>



            <Box component="form" onSubmit={handleSubmit} noValidate 
          
            
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="idea"
                            label="Idea"
                            name="idea"
                            autoComplete="idea"
                            value={idea}
                            onChange={(e) => setIdea(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="descripcion"
                            label="Descripción"
                            name="descripcion"
                            multiline
                            rows={4}
                            value={descripcion}
                            onChange={(e) => setDescripcion(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="un-label">Unidad de Negocio</InputLabel>
                            <Select
                                labelId="un-label"
                                id="un"
                                value={un}
                                onChange={(e) => setUn(e.target.value)}
                                label="Unidad de Negocio"
                            >
                                {unidadesNegocio.map(unidad => (
                                    <MenuItem key={unidad} value={unidad}>{unidad}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>
            
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="impacto-label">Impacto</InputLabel>
                            <Select
                            
                                labelId="impacto-label"
                                id="impacto"
                                value={impacto}
                                onChange={(e) => setImpacto(e.target.value)}
                                label="Impacto"
                            >
                                {impactos.map(impacto => (
                                    <MenuItem key={impacto} value={impacto}>{impacto}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <Grid item xs={12}>
                             <br />
                             <FormControl fullWidth>
                             <InputLabel id="impacto-label">Tu solicitud es parte de algún proceso documentado en Compucad?</InputLabel>

                             <Select
                            
                            labelId="prceso-label"
                            id="TieneProceso"
                            value={TieneProceso}
                            onChange={(e) => setTieneProceso(e.target.value)}
                            label="¿La solicitud propuesta es parte de algún proceso documentado en Compucad?"
                        >
                            <MenuItem value="Si">Si</MenuItem>
                            <MenuItem value="No">No</MenuItem>
                            <MenuItem value="Si, pero requiere actualización">Si, pero requiere actualización</MenuItem>
                        </Select>
                        </FormControl>
                           </Grid>

                    </Grid>
                    <Grid item xs={12}>
                        <FormControl component="fieldset" variant="standard">
                            <Typography component="legend">Clasificación</Typography>
                            <FormGroup>
                                {clasificaciones.map((clasif) => (
                                    <FormControlLabel
                                        key={clasif}
                                        control={
                                            <Checkbox
                                                checked={clasificacion.includes(clasif)}
                                                onChange={handleClasificacionChange}
                                                name={clasif}
                                            />
                                        }
                                        label={clasif}
                                    />
                                ))}
                            </FormGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            id="nombre"
                            label="Nombre como apareces en Teams"
                            name="nombre"
                            value={nombre}
                            onChange={(e) => setNombre(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <p>Completa el correo, no incluyas el "@compucad.com.mx </p>
                        <div style={{display:"flex"}}>
                        <TextField
                            required
                            fullWidth
                            id="correo"
                            label="Primera parte del correo"
                            name="correo"
                            value={correo}
                            onChange={(e) => setCorreo(e.target.value)}
                        />
                        <p style={{marginLeft:'10px'}}>@compucad.com.mx</p>
                        </div>
                    </Grid>
                </Grid>
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                >
                    Enviar Solicitud
                </Button>
            </Box>
        </Container>

        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              ¡Gracias por tu aportación!
            </Typography>
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Tu idea ha sido registrada con éxito. Estás ayudando a impulsar la transformación digital de la empresa.
            </Typography>

            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                Folio de tu idea: {folio}
            </Typography>
            
            <Button onClick={handleCloseModal} sx={{ mt: 2 }}>
              Cerrar
            </Button>
          </Box>
        </Modal>
        </div>
        </div>
    );
};

export default RegistrarIdea;
