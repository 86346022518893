import React, { useState, useEffect } from 'react';
import { GetAllSolicitudes } from '../APIS/Solicitudes';
import {GetAllEncuestas2} from '../APIS/Encuestas';
import { GetAllProyectos } from '../APIS/proyectos';

import { CircularProgress, Container, TextField, FormControl, InputLabel, Select, MenuItem, Card, CardContent, Typography, Grid, Box,Modal,Stack,Button, IconButton, Menu,CardHeader } from '@mui/material';

const GridComponent = (props) => {
  const { text1, text2, text3, text4 } = props;
  const [numeroDeSolicitudes, setNumeroDeSolicitudes] = useState(0); // Estado para almacenar el número de solicitudes
const [numeroDeProyectos, setNumeroDeProyectos] = useState(0); // Estado para almacenar el número de solicitudes
const [promedio , setPromedio] = useState(0);
const [ProyectoMejorCalificado, setProyectoMejorCalificado] = useState("Cargando...");
const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchDataFromApi = async () => {
      try {
        const jsonData = await GetAllSolicitudes();
        console.log(jsonData);
        // Filtrar las solicitudes con Status "En revisión"
        const solicitudesEnRevision = jsonData.filter(solicitud => solicitud.Status === 'En revisión');
        console.log(solicitudesEnRevision);
        // Actualizar el estado con el número de solicitudes en revisión
        setNumeroDeSolicitudes(solicitudesEnRevision.length);
        setLoading(false);
      } catch (error) {
       
        console.error('Error fetching data:', error);
      }
    };
    

    const fetchDataFromApi2 = async () => {
      try {
        const jsonData = await GetAllProyectos();
        console.log(jsonData);
        // Filtrar los proyectos con Status "En productivo"
        const proyectosEnProductivo = jsonData.filter(proyecto => proyecto.Status === 'En productivo');
        console.log(proyectosEnProductivo);
        setLoading(false);
        // Actualizar el estado con el número de proyectos en productivo
        setNumeroDeProyectos(proyectosEnProductivo.length);
      } catch (error) {
        console.error('Error fetching data:', error);
   
      }
    };
    

    const fetchDataFromApi3 = async () => {
      try {
        const jsonData = await GetAllEncuestas2();
        console.log(jsonData);
        let sum = 0;
        for (let i = 0; i < jsonData.length; i++) {
          sum += jsonData[i].PromedioGeneral;
        }
        setPromedio(sum / jsonData.length);

        //obten el nombre del proyecto con el promedio mas alto
        let max = jsonData[0].PromedioGeneral;
        let index = 0;
        for (let i = 1; i < jsonData.length; i++) {
          if (jsonData[i].PromedioGeneral > max) {
            max = jsonData[i].PromedioGeneral;
            index = i;
          }
        }
        console.log(promedio);
        setProyectoMejorCalificado(jsonData[index].NombreProyecto);
        setLoading(false);
      } catch (error) {
       
        console.error('Error fetching data:', error);
      }
    };

    fetchDataFromApi();
    fetchDataFromApi2();
    fetchDataFromApi3();
  }, []);

  return (
    <div>
      {loading ? (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
        <CircularProgress />
      </Box>
    ) : (

      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gridTemplateRows: '1fr 1fr', gap: '10px' }}>

        <div style={{ gridColumn: 'span 2', gridRow: 'span 1', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)', padding: '20px', fontSize: '22px', background: '#FF9E1B', color:'white' }}>
          {"El proyecto mejor calificado es: " + ProyectoMejorCalificado}
        </div>
        <div style={{ gridColumn: 'span 1', gridRow: 'span 2', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', padding: '20px', fontSize: '68px', background: '#34A798',color:'white' }}>
          {"Promedio: " + ((promedio.toFixed(2) /5 )*100) + "%"}  
        </div>
        <div style={{ gridColumn: 'span 1', gridRow: 'span 1', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', padding: '20px', fontSize: '18px', background: '#0067A0',color:'white' }}>
         {"Proyectos en productivo: " + numeroDeProyectos}
        </div>
        <div style={{ gridColumn: 'span 1', gridRow: 'span 1', display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', padding: '20px', fontSize: '18px', background: '#d65238',color:'white' }}>
          {"Proyectos en cola: " + numeroDeSolicitudes}
        </div>
   
      </div>
    )}
    </div>
  );
};

export default GridComponent;
