import React, { useState, useEffect,useContext } from 'react';
import { TextField, Button, Box, Card, CardContent, Typography, CardActions, IconButton, Grid, MenuItem, Autocomplete, Select,InputLabel, Dialog, DialogContent } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Auth0Context, useAuth0 } from "@auth0/auth0-react";
import AvisoLogin from './AvisoLogin';
import { PostUserStory, GetAllUserStories } from '../APIS/Userstories';
import { GetAllProyectos } from '../APIS/proyectos';
import MyDrawerComponent from './NavBar';
import 'react-quill/dist/quill.snow.css';
const UserStoriesPorProyecto = ({idProyecto}) => {
  const { isAuthenticated } = useAuth0();
  const [userStories, setUserStories] = useState([]);
  const [filter, setFilter] = useState('');
  const [newStory, setNewStory] = useState({ IDProyecto: '', Historias: '', importancia: '',Comentarios:'Sin comentarios', Estatus:'Pendiente' });
  const [expandedId, setExpandedId] = useState(null);
  const [proyectos, setProyectos] = useState([]);
  const [intermediateStory, setIntermediateStory] = useState({
    como: '',
    quiero: '',
    para: '',
    ca: ''
  });
  const [openDialog, setOpenDialog] = useState(false);
const { user } = useContext(Auth0Context);
const [idprotectoFinal , setIdProyectoFinal] = useState('' );
const handleOpenDialog = () => setOpenDialog(true);
const handleCloseDialog = () => setOpenDialog(false);
  useEffect(() => {
    const fetchUserStories = async () => {
      const stories = await GetAllUserStories();
      // Filtrar las historias por el ID del proyecto que recibimos como parámetro
      const filteredStories = stories.filter(story => story.Proyecto && String(story.Proyecto.Codigo) === String(idProyecto));

      setUserStories(filteredStories);
    };
    const fetchProyectos = async () => {
      const proyectosFetched = await GetAllProyectos();
      setProyectos(proyectosFetched);

      //busca el proyecto que su campo de codigo sea igual al idProyecto y guarda el id del proyecto en newStory
      const proyecto = proyectosFetched.find(proyecto => proyecto.Codigo === idProyecto);
      setIdProyectoFinal(proyecto.id);
    };
    fetchProyectos();
    fetchUserStories();
  }, [ idProyecto ]);

  const handleChange = (event, value) => {
    setNewStory({ ...newStory, IDProyecto: value?.id || '' });
  };

  const handleInputChange = (e, name) => {
    const { value } = e.target;
    setNewStory({ ...newStory, [name]: value });
    setIntermediateStory({ ...intermediateStory, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Construir la historia completa
    const historiaCompleta = `Como ${intermediateStory.como}, quiero ${intermediateStory.quiero}, para ${intermediateStory.para}, CA ${intermediateStory.ca}.`;
    
    // Preparar el objeto a enviar
    const storyToSubmit = {
      IDProyecto: idprotectoFinal,
      Historias: historiaCompleta,
      importancia: newStory.importancia,
      Comentarios: "Sin comentarios",
      Estatus: "Pendiente",
      Correo:"prueba"
    };
    console.log(storyToSubmit);
    await PostUserStory(storyToSubmit);
    
    // Aquí podrías decidir si quieres o no resetear los campos intermedios después de enviar
    setIntermediateStory({ como: '', quiero: '', para: '', ca: '' });
    
    // Obtener las historias actualizadas
    const updatedStories = await GetAllUserStories();
    const filteredStories = updatedStories.filter(story => story.Proyecto && String(story.Proyecto.Codigo) === String(idProyecto));

      setUserStories(filteredStories);
    
    
    // Resetear el formulario si es necesario
    setNewStory({ IDProyecto: '', Historias: '', importancia: '' });
  };
  
  const toggleExpansion = (index) => {
    setExpandedId(expandedId === index ? null : index);
  };

  const filteredStories = filter
    ? userStories.filter(story => story.Proyecto.NombreProyecto.toString().includes(filter))
    : userStories;
    
    const handleEditorChange = (content) => {
        setNewStory({ ...newStory, Historias: content });
      };
      const getColorByImportance = (importancia) => {
        switch (importancia) {
          case 'Alta':
            return '#f55d5d';
            case 'ALTA':
              return '#f55d5d'; 
          case 'Media':
            return '#4caf50';
          case 'Baja':
            return '#9e9e9e'; // Verde para baja importancia
          default:
            return '#e0e0e0'; // Gris para cualquier otro caso o si no está definido
        }
      };
      
      return (
        <div>
          {isAuthenticated ? (
            <div>
              <div style={{ paddingRight: 100, paddingLeft: 100, paddingTop: 0 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={4} mt={3}>
                    <Typography variant="h5" sx={{ marginBottom: 2 }} style={{ textAlign: "center" }}>Agregar User Story</Typography>
                    <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginBottom: 2 }}>
                      {/* Tus otros campos como Proyecto e Importancia aquí */}
              {/* <InputLabel id="importancia-label">Proyecto</InputLabel>

                <Autocomplete
                  options={proyectos}
                  getOptionLabel={(option) => option.NombreProyecto || ''} // Asumiendo que el objeto proyecto tiene una propiedad 'nombre'
                  renderInput={(params) => <TextField {...params} label="" />}
                  onChange={handleChange}
                  fullWidth
                /> */}
             <InputLabel id="importancia-label">Importancia</InputLabel>
             <Select
                  label="Importancia"
                  value={newStory.importancia}
                  onChange={(e) => handleInputChange(e, 'importancia')}
                  fullWidth
                >
                    <MenuItem value="Alta"  style={{ width: '100%' }}>Alta</MenuItem>
                    <MenuItem value="Media" style={{ width: '100%' }}>Media</MenuItem>
                    <MenuItem value="Baja"  style={{ width: '100%' }}>Baja</MenuItem>
                </Select>
      <InputLabel id="importancia-label">Historia</InputLabel>
                      <TextField label="Como" value={intermediateStory.como} onChange={(e) => handleInputChange(e, 'como')} fullWidth />
                      <TextField label="Quiero" value={intermediateStory.quiero} onChange={(e) => handleInputChange(e, 'quiero')} fullWidth />
                      <TextField label="Para" value={intermediateStory.para} onChange={(e) => handleInputChange(e, 'para')} fullWidth />
                      <TextField label="CA" value={intermediateStory.ca} onChange={(e) => handleInputChange(e, 'ca')} fullWidth />
                      <Button type="submit" variant="contained" sx={{ alignSelf: 'center' }}>Agregar User Story</Button>

                      <Box sx={{ my: 2, p: 2, border: '1px solid #ccc' }}>
                        <Typography variant="h6" gutterBottom>Vista previa de la historia:</Typography>
                        <Typography>Como {intermediateStory.como}, quiero {intermediateStory.quiero}, para {intermediateStory.para}, donde los criterios de aceptación son {intermediateStory.ca}.</Typography>
                      </Box>
                      {/* <Button variant="outlined" onClick={handleOpenDialog} sx={{ mt: 2 }}>Mostrar Ejemplo</Button> */}
                    </Box>
                  </Grid>
                  <Grid item xs={12} md={8} mt={4}>
  {/* <TextField
    label="Filtrar por nombre de proyecto"
    variant="outlined"
    fullWidth
    value={filter}
    onChange={(e) => setFilter(e.target.value)}
    sx={{ marginBottom: 2 }}
  /> */}
  {filteredStories.length > 0 ? (
    filteredStories.map((story, index) => (
      <Card key={index} sx={{ marginBottom: 2, backgroundColor: getColorByImportance(story.importancia) }}>
        <CardActions disableSpacing>
          <Typography variant="h6" sx={{ flexGrow: 1, color: 'white' }}>
            {story.Proyecto.NombreProyecto} - Importancia: {story.importancia}
          </Typography>
          <IconButton onClick={() => toggleExpansion(index)}>
            {expandedId === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </CardActions>
        {expandedId === index && (
          <CardContent>
            <Typography style={{ color: 'white' }} dangerouslySetInnerHTML={{ __html: story.Historias }} />
           <br />
           {/* <div>
            <TextField style={{ color: 'white' }} label="Comentarios" value={story.Comentarios} onChange={(e) => handleInputChange(e, 'Comentarios')} fullWidth multiline rows={4}/>
           <Select style={{ color: 'white' }} label="Estatus" value={story.Estatus} onChange={(e) => handleInputChange(e, 'Estatus')} fullWidth>
                    <MenuItem  value="Pendiente">Pendiente</MenuItem>
                    <MenuItem value="En Proceso">En Proceso</MenuItem>
                    <MenuItem value="Terminado">Terminado</MenuItem>
                </Select>
           
                </div>
            <Button variant="contained" sx={{ alignSelf: 'center' }}>Actualizar Historia</Button> */}
            
          </CardContent>
        )}
      </Card>
    ))
  ) : (
    <Typography variant="subtitle1" style={{ marginTop: 20 }}>
      No se encontraron historias de usuario.
    </Typography>
  )}
</Grid>

                </Grid>
                <Dialog open={openDialog} onClose={handleCloseDialog}>
                  <DialogContent>
                    <img src="url_de_tu_imagen_de_ejemplo.jpg" alt="Ejemplo" style={{ maxWidth: '100%', height: 'auto' }} />
                  </DialogContent>
                </Dialog>
              </div>
            </div>
          ) : <AvisoLogin />}
        </div>
      );
      
};

export default UserStoriesPorProyecto;
