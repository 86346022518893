
import React from 'react';
import MatrizPrioridad from '../Components/MatrizPrioridad';
import MyDrawerComponent from '../Components/NavBar';

const MatrizPrioridadPages = () => {
  // Retornar el JSX que se renderizará
  return (
    <div>
            <MyDrawerComponent pageName="Matriz prioridad" selectedMenuItem="Matriz prioridad" />

     <MatrizPrioridad/>
    </div>
  );
};

// Exportar el componente para su uso en otros archivos
export default MatrizPrioridadPages;
