import React, { useState, useEffect } from 'react';
import { Typography, Box, Container, Card, CardContent } from '@mui/material';
import { useParams } from 'react-router-dom';
import { GetAllEncuestas } from '../APIS/Encuestas';
import { useAuth0 } from "@auth0/auth0-react";
import AvisoLogin from '../Components/AvisoLogin';
import MyDrawerComponent from '../Components/NavBar';
import { GetSolicitante, GetSolicitudByid } from '../APIS/Solicitudes';

const DetallesEncuesta = () => {
    const { parametro } = useParams();
    const [detalleEncuesta, setDetalleEncuesta] = useState(null);
    const [promedioRespuestas, setPromedioRespuestas] = useState(0);
    const [solicitante, setSolicitante] = useState('');
    const [IDSolicitante, setIDSolicitante] = useState('');

    useEffect(() => {
        const fetchEncuestaData = async () => {
            try {
                const data = await GetAllEncuestas(parametro);
                if (data) {
                    setDetalleEncuesta(data);
                    setSolicitante(data.IDSolici);
                    console.log(data);
                    let total = data.P1+ data.P3 + data.P4 + data.P5 + data.P6 + data.P8;

                    let equivalente100 = (total / 30) * 100;

                    setPromedioRespuestas(equivalente100);
                    setIDSolicitante(data.IDSolicitud);
                    console.log(data.IDSolicitud);
                }
            } catch (error) {
                console.error('Error al obtener los detalles de la encuesta:', error);
            }
        };

        fetchEncuestaData();
    }, [parametro]);

    useEffect(() => {
        const fetchSolicitanteData = async () => {
            try {
                const data = await GetSolicitudByid(IDSolicitante);
                if (data) {
                    setSolicitante(data.solicitante.Correo);
                }
            } catch (error) {
                console.error('Error al obtener los detalles del solicitante:', error);
            }
        };

        fetchSolicitanteData();
    }, [solicitante]);

    const opcionesTextuales = {
        P1: ["Completamente insatisfecho", "Algo insatisfecho", "Neutral", "Mayormente satisfecho", "Completamente satisfecho"],
        P2: ["Excesivamente largo", "Insuficiente para la planeación necesaria", "Adecuadamente planificado", "Más rápido de lo esperado"],
        P3: ["Significativamente retrasado", "Ligeramente retrasado", "Se completó antes de lo esperado", "Se cumplió el tiempo estimado"],
        P4: ["Muy ineficiente", "Ineficiente", "Aceptable", "Eficiente", "Muy eficiente"],
        P5: ["Completamente insatisfecho", "Algo insatisfecho", "Neutral", "Mayormente satisfecho", "Completamente satisfecho"],
        P6: ["Totalmente insuficiente", "Insuficiente", "Neutral", "Suficiente", "Totalmente suficiente"],
        P7: ["Malo", "Regular", "Bueno", "Excelente", "No aplicable/No recibí soporte"],
        P8: ["1", "2", "3", "4", "5"]
    };

    const nombresPreguntas = {
        P1: "¿En qué medida los resultados del desarrollo cumplieron con tus expectativas?",
        P3: "¿Los tiempos de desarrollo se alinearon con lo planificado?",
        P4: "¿En cuanto a las pruebas y entrega funcional del desarrollo, ¿cómo calificarías la gestión de tiempo?",
        P5: "¿Cómo evaluarías la facilidad de uso y experiencia de usuario?",
        P6: "¿Fue el periodo de garantía suficiente para detectar y corregir inconsistencias en el entorno de producción?",
        P8: "Califica el proceso general de Transformación Digital en una escala del 1 al 5, donde 1 es 'Muy insatisfecho' y 5 es 'Muy satisfecho'"
    };

    const getColorAndEmoji = (value) => {
        if (value >= 80) return { color: 'green', emoji: '🥳' };
        if (value >= 40) return { color: 'blue', emoji: '😐' };
        return { color: 'red', emoji: '😠' };
    };

    const promedioFormateado = typeof promedioRespuestas === 'number' ? promedioRespuestas.toFixed(2) : promedioRespuestas;
    const { color: promedioColor, emoji: promedioEmoji } = getColorAndEmoji(promedioFormateado);

    return (
        <div>
            <MyDrawerComponent pageName="Detalles Encuesta" selectedMenuItem="Contestar Encuesta" />
            <Container>
                {useAuth0().isAuthenticated ? (
                    detalleEncuesta ? (
                        <div>
                            <Box marginTop={4}>
                                <center>
                                    <Typography variant="h4" gutterBottom>
                                        Encuesta de satisfacción proyecto - {detalleEncuesta.NombreProyecto}
                                    </Typography>
                                    <Typography variant="h5" gutterBottom>
                                        Promedio de respuestas: <span style={{ color: promedioColor }}>{promedioFormateado}% {promedioEmoji}</span>
                                    </Typography>
                                    <Typography variant="h5" gutterBottom>
                                        Solicitante: {solicitante}
                                    </Typography>
                                    <br />
                                    <Typography variant="h6" gutterBottom>
                                        Calificación numérica
                                    </Typography>
                                </center>
                                {Object.entries(detalleEncuesta).map(([key, value]) => {
                                    if (key.startsWith("P") && key !== "P9" && key !== "P10" && key !== "P2" && key !== "P7" && opcionesTextuales[key] && value > 0 && value <= opcionesTextuales[key].length) {
                                        const porcentaje = (value / 5) * 100;
                                        const { color, emoji } = getColorAndEmoji(porcentaje);
                                        return (
                                            <Card key={key} sx={{ mb: 2 }}>
                                                <CardContent>
                                                    <Typography variant="body1" component="span" fontWeight="bold">{`${nombresPreguntas[key]}: `}</Typography>
                                                    <Typography variant="body1" component="span">{`${opcionesTextuales[key][value - 1]} `}</Typography>
                                                    <Typography variant="body1" component="span" style={{ color }}>{`(${porcentaje}%) ${emoji}`}</Typography>
                                                </CardContent>
                                            </Card>
                                        );
                                    } else if (key === "P9" || key === "P10") {
                                        return (
                                            <Card key={key} sx={{ mb: 2 }}>
                                                <CardContent>
                                                    <Typography variant="body1" component="span" fontWeight="bold">{`${key === "P9" ? "¿Qué aspectos del servicio de TD consideras que requieren mejora?" : "Comentarios adicionales"}: `}</Typography>
                                                    <Typography variant="body1" component="span">{`${value}`}</Typography>
                                                </CardContent>
                                            </Card>
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        </div>
                    ) : (
                        <Typography>Cargando detalles de la encuesta...</Typography>
                    )
                ) : (
                    <AvisoLogin />
                )}
            </Container>
        </div>
    );
};

export default DetallesEncuesta;
